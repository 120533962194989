import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';

import { DEFAULT_TOAST_OPTIONS } from '@/constants/ui';

type RequestState<T, D = unknown> = {
  loading: boolean;
  error?: string;
  data?: T;
  setData: (data: T) => void;
  trigger: (data?: D) => void;
};

const useRequestState = <T, D = unknown>(
  promise: (data?: D) => Promise<CustomResponse<T>>,
  deps: any[] = [],
  config: {
    withNotify?: boolean;
    condition?: boolean;
    onSuccess?: (data: T) => void;
    onError?: (error: string) => void;
    onFinally?: () => void;
  } = {
    withNotify: true,
    condition: true,
  },
): RequestState<T, D> => {
  const { withNotify = true, condition = true, onFinally, onSuccess, onError } = config;
  const toast = useToast();
  const { t } = useTranslation();
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const trigger = useCallback(
    (data?: D) => {
      setError('');
      setLoading(true);

      promise(data)
        .then((result: CustomResponse<T>) => {
          if (result.success) {
            setData(result.data);

            if (onSuccess) {
              onSuccess(result.data as T);
            }
          } else {
            const errMessage = result.error || t('server.error');

            if (onError) {
              onError(errMessage);
            }

            setError(errMessage);
          }
        })
        .catch((err) => {
          const errorMessage = err.message || t('server.error');

          if (onError) {
            onError(errorMessage);
          }

          setError(errorMessage);
        })
        .finally(() => {
          setLoading(false);

          if (onFinally) {
            onFinally();
          }
        });
    },
    // eslint-disable-next-line
    [t, onFinally, onSuccess, ...deps],
  );

  useEffect(() => {
    if (condition) {
      trigger();
    }
    // eslint-disable-next-line
  }, [...deps, condition]);

  useEffect(() => {
    if (!!error && withNotify) {
      toast({
        ...DEFAULT_TOAST_OPTIONS,
        description: error,
      });
    }
  }, [error, toast, withNotify]);

  return {
    loading,
    error,
    data,
    setData,
    trigger,
  };
};

export default useRequestState;
