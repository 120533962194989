import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { FaPencilAlt, FaTrash, FaUsers } from 'react-icons/fa';
import { IoReload, IoAdd } from 'react-icons/io5';

import DataTable from '@/components/Table';
import { getUsers, deleteUser } from '@/api/admin';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import UserForm from './components/form';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ActionBar from '@/components/ActionBar';
import { ADMIN_ROLE } from '@/constants/app';
import { formateDateLocal } from '@/utils/date';

const UsersPage = () => {
  usePageInfo({ title: 'pages.users' });

  const { properties } = AppStore;
  const { t } = useTranslation();

  const [userToDelete, setUserToDelete] = useState<Admin | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Admin | null | undefined>(undefined);

  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Admin>({
    initialSortBy: 'name',
    initialOrder: 'asc',
  });

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Admin>>(
    () => getUsers({ page, limit, sortBy, order, search }),
    [page, limit, sortBy, order, search],
  );

  const handleDialogToggle = useCallback((user?: Admin | null) => {
    setUserToDelete(user ?? null);
    setIsDeleteDialogOpen(!!user);
  }, []);

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    handleDialogToggle();
  }, [dataTrigger, handleDialogToggle]);

  const { trigger: deleteTrigger } = useRequestState<'ok'>(
    () => deleteUser(userToDelete?._id!),
    [userToDelete?._id],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          setSearch={setSearch}
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('users.total')}
              value={`${data?.totalDocs ?? '-'}`}
              icon={FaUsers}
            />
          }
          actions={
            <>
              <ActionButton mini icon={IoAdd} onClick={() => setSelectedUser(null)} />

              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<Admin>
          mt="20px"
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'name',
              header: t('users.name'),
              accessor: 'name',
              isSortable: true,
            },
            {
              id: 'lastName',
              header: t('users.last_name'),
              accessor: 'lastName',
              isSortable: true,
            },
            {
              id: 'email',
              header: t('users.email'),
              accessor: 'email',
              isSortable: true,
            },
            {
              id: 'role',
              header: t('users.role'),
              accessor: 'role',
              isSortable: true,
            },
            {
              id: 'properties',
              header: t('users.properties'),
              accessor: 'properties',
              cell: (user: Admin) => (
                <HStack>
                  {user.role === ADMIN_ROLE.ADMIN ? (
                    <Badge colorScheme="green">{t('users.manages_all')}</Badge>
                  ) : (
                    user.properties.map((id: string) => (
                      <Badge colorScheme="pink" key={id} maxW="100px" isTruncated>
                        {properties[id]?.name || id}
                      </Badge>
                    ))
                  )}
                </HStack>
              ),
            },
            {
              id: 'lastVisit',
              header: t('users.last_visit'),
              accessor: 'lastVisit',
              isSortable: true,
              center: true,
              cell: (user: Admin) => (
                <Text>{formateDateLocal(user.lastVisit, 'DD-MM-YYYY HH:mm')}</Text>
              ),
            },
            {
              id: 'actions',
              header: t('users.actions'),
              accessor: null,
              center: true,
              cell: (user: Admin) => (
                <HStack justify="center">
                  <IconButton
                    aria-label="Edit"
                    size="sm"
                    icon={<FaPencilAlt />}
                    onClick={() => setSelectedUser(user)}
                  />

                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    icon={<FaTrash />}
                    onClick={() => handleDialogToggle(user)}
                  />
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        title={t('users.delete_confirmation_title')}
        body={t('users.delete_confirmation_body', {
          name: `${userToDelete?.name} ${userToDelete?.lastName}`,
        })}
        onConfirm={deleteTrigger}
        onCancel={() => handleDialogToggle()}
      />

      <UserForm
        data={selectedUser}
        setSelected={setSelectedUser}
        onSubmit={dataTrigger}
      />
    </>
  );
};

export default observer(UsersPage);
