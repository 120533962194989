import { request } from '@/utils/request';
import { DEFAULT_PAGINATION_OPTIONS } from '@/constants/app';

export const getUnits = async (
  query: PaginationRequestType = DEFAULT_PAGINATION_OPTIONS,
) =>
  request<PaginateResult<Unit>>({
    url: '/unit',
    query,
  });

export const getUnit = async (id: string) =>
  request<Unit>({
    url: '/unit',
    param: id,
  });

export const createUnit = async (body: UnitFormData) =>
  request<Unit>({
    url: '/unit',
    method: 'POST',
    body,
  });

export const updateUnit = async (id: string, body: UnitFormData) =>
  request<Unit>({
    url: '/unit',
    method: 'PUT',
    body,
    param: id,
  });

export const deleteUnit = async (id: string) =>
  request<'ok'>({
    url: '/unit',
    method: 'DELETE',
    param: id,
  });
