import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { IoAdd, IoReload } from 'react-icons/io5';
import { TbReceipt2 } from 'react-icons/tb';
import { FaDollarSign, FaEnvelope, FaTrash } from 'react-icons/fa';
import { HiReceiptRefund } from 'react-icons/hi';
import { MdRefresh } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { GrClose } from 'react-icons/gr';

import { getInvoices, removeInvoice, sendInvoice, refundInvoice } from '@/api/invoice';
import { formatDate } from '@/utils/date';
import AppStore from '@/stores/app';
import DataTable from '@/components/Table';
import Pagination from '@/components/Pagination';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import Search from '@/components/Search';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { mapInvoicesToColor, mapPaymentToColor, mapTypeToColor } from '@/utils/invoices';
import InvoiceForm from './form';
import CloseInvoiceManually from './CloseManually';
import ActionBar from '@/components/ActionBar';
import { DEFAULT_TOAST_OPTIONS } from '@/constants/ui';
import { ROUTES } from '@/constants/routes';
import { INVOICE_STATUS, UPAID_INVOICE_STATUSES } from '@/constants/app';
import TableFilters from '@/components/TableFilters';
import { BsFilter } from 'react-icons/bs';
import useFilters from '@/hooks/useDataFilters';
import { isFiltered } from '@/utils/dataFilters';

type Props = {
  title?: string;
  contract?: Contract;
  byContract?: boolean;
  layout?: {
    search?: boolean;
    total?: boolean;
    add?: boolean;
    reload?: boolean;
    filters?: boolean;
  };
  miniActions?: boolean;
  extraActions?: {
    beforeAll?: React.ReactNode;
    before?: React.ReactNode;
    after?: React.ReactNode;
    afterAll?: React.ReactNode;
  };
};

type ActionType = 'delete' | 'cancel' | 'send' | 'refund';

type Filters = { status: InvoiceStatus[] };

const FILTERS: PaginationFilters<Filters> = {
  status: {
    title: 'invoices.status',
    values: Object.values(INVOICE_STATUS).map((el) => ({
      value: el,
      selected: false,
      tKey: `invoices.statuses.${el}`,
    })),
  },
} as const;

const InvoicesTable = ({
  title,
  contract,
  byContract = false,
  miniActions = false,
  layout = {
    search: true,
    total: true,
    add: true,
    reload: true,
    filters: true,
  },
}: Props) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [actionType, setActionType] = useState<ActionType | null>(null);
  const [actionData, setActionData] = useState<Invoice | null>(null);
  const [selectedClose, setSelectedClose] = useState<Invoice | null | undefined>(
    undefined,
  );
  const [selectedCreate, setSelectedCreate] = useState<null | undefined>(undefined);

  const handleConfirmDialog = useCallback((type: ActionType, data?: Invoice | null) => {
    setActionType(type);
    setActionData(data ?? null);
    setIsConfirmDialogOpen(!!data);
  }, []);

  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const { selectedProperty } = AppStore;
  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Invoice, keyof Invoice>({
    initialSortBy: 'createdAt',
    initialOrder: 'desc',
  });

  const { isFiltersOpen, filters, setFiltersOpen, setFilter } = useFilters<Filters>({
    initialFilters: { status: [] },
  });

  const conditionByContract = byContract ? !!contract : true;

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Invoice>>(
    () => getInvoices({ page, limit, sortBy, order, search, ...filters }, contract?._id),
    [page, limit, sortBy, order, selectedProperty, search, filters, contract?._id],
    { condition: conditionByContract },
  );

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    setIsConfirmDialogOpen(false);
  }, [dataTrigger]);

  const onSendSuccess = useCallback(() => {
    dataTrigger();
    setIsConfirmDialogOpen(false);
  }, [dataTrigger]);

  const onRefundSuccess = useCallback(
    (res: string) => {
      dataTrigger();
      setIsConfirmDialogOpen(false);

      toast({
        ...DEFAULT_TOAST_OPTIONS,
        title: res,
        status: 'success',
      });
    },
    [dataTrigger, toast],
  );

  const { trigger: deleteTrigger, loading: deleteLoading } = useRequestState<'ok'>(
    () => removeInvoice(actionData?._id!),
    [actionData],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  const { trigger: sendTrigger, loading: sendLoading } = useRequestState<'ok'>(
    () => sendInvoice(actionData?._id!),
    [actionData],
    { condition: false, onSuccess: onSendSuccess },
  );

  const { trigger: refundTrigger, loading: refundLoading } = useRequestState<'ok'>(
    () => refundInvoice(actionData?._id!),
    [actionData],
    { condition: false, onSuccess: onRefundSuccess },
  );

  const handleConfirm = useCallback(async () => {
    if (actionType === 'delete' || actionType === 'cancel') {
      deleteTrigger();
    } else if (actionType === 'send') {
      sendTrigger();
    } else if (actionType === 'refund') {
      refundTrigger();
    }
  }, [actionType, deleteTrigger, sendTrigger, refundTrigger]);

  const getMiniActions = () => {
    if (!miniActions) return undefined;

    const result = [];

    if (layout.filters) {
      result.push({
        icon: BsFilter,
        onClick: () => setFiltersOpen(true),
        redDot: isFiltered(filters),
      });
    }

    if (layout.add) {
      result.push({ icon: IoAdd, onClick: () => setSelectedCreate(null) });
    }

    if (layout.reload) {
      result.push({ icon: MdRefresh, onClick: dataTrigger });
    }

    return result;
  };

  const loading = deleteLoading || sendLoading || refundLoading;

  const confirmDialogTexts = () => {
    const client = `${actionData?.contract.data.client.name} ${actionData?.contract.data.client.lastName}`;
    const unit = actionData?.contract.data.unit.name;
    const data = { client, unit };

    if (actionType === 'delete') {
      return {
        title: t('invoices.delete_confirmation_title'),
        body: t('invoices.delete_confirmation_body', data),
      };
    } else if (actionType === 'send') {
      return {
        title: t('invoices.send_confirmation_title'),
        body: t('invoices.send_confirmation_body', data),
      };
    } else if (actionType === 'refund') {
      return {
        title: t('invoices.refund_confirmation_title'),
        body: t('invoices.refund_confirmation_body', data),
      };
    } else if (actionType === 'cancel') {
      return {
        title: t('invoices.cancel_confirmation_title'),
        body: t('invoices.cancel_confirmation_body', data),
      };
    }
  };

  const dialogTexts = confirmDialogTexts();

  return (
    <>
      <Flex direction="column" h="100%">
        {!miniActions && (
          <ActionBar
            setSearch={setSearch}
            stats={
              <ActionButton
                mini
                hoverable={false}
                name={t('invoices.total')}
                value={`${data?.totalDocs ?? '-'}`}
                icon={TbReceipt2}
              />
            }
            actions={
              <>
                <ActionButton
                  mini
                  icon={BsFilter}
                  onClick={() => setFiltersOpen(true)}
                  redDot={isFiltered(filters)}
                />
                <ActionButton mini icon={IoAdd} onClick={() => setSelectedCreate(null)} />
                <ActionButton mini icon={IoReload} onClick={dataTrigger} />
              </>
            }
          />
        )}

        <DataTable<
          Invoice,
          'client' | 'unit' | 'actions' | 'items.quantity' | 'items.price' | 'stripe'
        >
          mt="20px"
          title={title}
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          search={
            miniActions && layout.search ? (
              <Search
                mini
                reverseColor
                onChange={setSearch}
                placeholder="invoices.search_by_client"
              />
            ) : undefined
          }
          actions={getMiniActions()}
          columns={[
            {
              id: 'status',
              header: t('invoices.status'),
              accessor: 'status',
              cell: (row) => (
                <Badge colorScheme={mapInvoicesToColor(row.status)}>
                  {t(`invoices.statuses.${row.status}`)}
                </Badge>
              ),
            },
            {
              id: 'unit',
              header: t('invoices.unit'),
              accessor: 'unit',
              cell: ({ contract }) => (
                <Link
                  onClick={() => navigate(`${ROUTES.UNITS}/${contract.data.unit._id}`)}
                >
                  {contract.data.unit.name}
                </Link>
              ),
            },
            {
              id: 'client',
              header: t('invoices.client'),
              accessor: 'client',
              cell: ({ contract }) => (
                <Text>
                  {`${contract?.data.client.name} ${contract?.data.client.lastName}`}
                </Text>
              ),
            },
            {
              id: 'amount',
              header: t('invoices.amount'),
              accessor: 'amount',
              isSortable: true,
            },
            {
              id: 'items.price',
              header: t('invoices.penalties'),
              accessor: 'items.price',
              cell: (row) => (
                <Text>
                  {row.items?.find((el) => el.type === 'OVERDUE_PENALTY')?.price ?? '-'}
                </Text>
              ),
            },
            {
              id: 'items.quantity',
              header: t('invoices.overdue_days'),
              accessor: 'items.quantity',
              cell: (row) => (
                <Text>
                  {row.items?.find((el) => el.type === 'OVERDUE_PENALTY')?.quantity ??
                    '-'}
                </Text>
              ),
            },
            {
              id: 'currency',
              header: t('invoices.currency'),
              accessor: 'currency',
              isSortable: true,
            },
            {
              id: 'description',
              header: t('invoices.product_desciption'),
              accessor: 'description',
              isSortable: true,
              maxW: '100px',
            },
            {
              id: 'method',
              header: t('invoices.payment_method'),
              accessor: 'method',
              isSortable: true,
              cell: (row) => (
                <Badge colorScheme={mapPaymentToColor(row.method)}>
                  {t(`invoices.payment_methods.${row.method}`)}
                </Badge>
              ),
            },
            {
              id: 'stripe',
              header: t('invoices.stripe'),
              accessor: 'stripe',
              cell: ({ contract }) => <Text>{contract.data.property.stripe?.name}</Text>,
            },
            {
              id: 'dueDate',
              header: t('invoices.due_date'),
              accessor: 'dueDate',
              isSortable: true,
              cell: (row) => <Text whiteSpace="nowrap">{formatDate(row.dueDate)}</Text>,
            },
            {
              id: 'paidAt',
              header: t('invoices.paid_at'),
              accessor: 'paidAt',
              isSortable: true,
              cell: (row) => <Text whiteSpace="nowrap">{formatDate(row.paidAt)}</Text>,
            },
            {
              id: 'type',
              header: t('invoices.type'),
              accessor: 'type',
              isSortable: true,
              cell: (row) => (
                <Badge colorScheme={mapTypeToColor(row.type)}>
                  {t(`invoices.types.${row.type}`)}
                </Badge>
              ),
            },
            {
              id: 'comments',
              header: t('invoices.comments'),
              accessor: 'comments',
              isSortable: true,
              maxW: '100px',
            },
            {
              id: 'actions',
              header: t('invoices.actions'),
              accessor: null,
              center: true,
              cell: (data: Invoice) => (
                <HStack justify="center">
                  {isUnpaid(data.status) && (
                    <Tooltip label={t('invoices.make_paid')} aria-label="Make Paid">
                      <IconButton
                        aria-label="Make Paid"
                        size="sm"
                        icon={<FaDollarSign />}
                        onClick={() => {
                          setSelectedClose(data);
                        }}
                      />
                    </Tooltip>
                  )}

                  <Tooltip label={t('invoices.send_invoice')} aria-label="Send Invoice">
                    <IconButton
                      aria-label="Send Invoice"
                      size="sm"
                      icon={<FaEnvelope />}
                      onClick={() => {
                        handleConfirmDialog('send', data);
                      }}
                    />
                  </Tooltip>

                  {data.status === 'PAID' && data.chargeId && (
                    <Tooltip label={t('invoices.make_refund')} aria-label="Make Refund">
                      <IconButton
                        aria-label="Make Refund"
                        size="sm"
                        icon={<HiReceiptRefund />}
                        onClick={() => {
                          handleConfirmDialog('refund', data);
                        }}
                      />
                    </Tooltip>
                  )}

                  {data.status === INVOICE_STATUS.DRAFT && (
                    <Tooltip
                      label={t('invoices.delete_invoice')}
                      aria-label="Delete Invoice"
                    >
                      <IconButton
                        aria-label="Delete"
                        size="sm"
                        icon={<FaTrash />}
                        onClick={() => handleConfirmDialog('delete', data)}
                      />
                    </Tooltip>
                  )}

                  {isUnpaid(data.status) && (
                    <Tooltip
                      label={t('invoices.cancel_invoice')}
                      aria-label="Cancel Invoice"
                    >
                      <IconButton
                        aria-label="Cancel"
                        size="sm"
                        icon={<GrClose />}
                        onClick={() => handleConfirmDialog('cancel', data)}
                      />
                    </Tooltip>
                  )}
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />

        <TableFilters<Filters>
          isOpen={isFiltersOpen}
          setFiltersOpen={setFiltersOpen}
          initialFilters={FILTERS}
          setFilter={setFilter}
        />
      </Flex>

      {conditionByContract && (
        <InvoiceForm
          contract={contract}
          data={selectedCreate}
          setSelected={setSelectedCreate}
          onSubmit={dataTrigger}
        />
      )}

      <CloseInvoiceManually
        data={selectedClose}
        setSelected={setSelectedClose}
        onSubmit={dataTrigger}
      />

      <ConfirmDialog
        loading={loading}
        isOpen={isConfirmDialogOpen}
        title={dialogTexts?.title ?? ''}
        body={dialogTexts?.body ?? ''}
        onConfirm={handleConfirm}
        onCancel={() => setIsConfirmDialogOpen(false)}
      />
    </>
  );
};

const isUnpaid = (status: InvoiceStatus) => {
  return UPAID_INVOICE_STATUSES.includes(status);
};

export default observer(InvoicesTable);
