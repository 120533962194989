import { Card, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaDollarSign } from 'react-icons/fa';
import { MdPercent } from 'react-icons/md';
import { TbActivity } from 'react-icons/tb';

import { getUnit } from '@/api/unit';
import useRequestState from '@/hooks/useRequestState';
import usePageInfo from '@/hooks/usePageInfo';
import ActionButton from '@/components/ActionButton';
import ExpensesTable from '../Expenses/components/ExpensesTable';

const UnitDetails = () => {
  usePageInfo({ title: 'pages.unit_details' });

  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useRequestState<Unit>(() => getUnit(id!), [id], {
    condition: !!id,
  });

  return (
    <>
      {(data?.name || data?.comments) && (
        <Card width="fit-content" mb="20px">
          <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold">
            {data?.name}
          </Text>

          {data?.comments && (
            <Text fontSize={{ base: 'sm', md: 'md' }}>
              {t('unit_details.comments')}: {data?.comments}
            </Text>
          )}
        </Card>
      )}

      <Flex gap={5} direction="column" mb="20px">
        <SimpleGrid columns={{ base: 2, md: 4, lg: 4, xl: 5, '2xl': 7 }} gap="20px">
          <ActionButton
            mini
            hoverable={false}
            name={t('unit_details.status')}
            value={data?.isActive ? t('unit_details.active') : t('unit_details.inactive')}
            icon={TbActivity}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('unit_details.price')}
            value={data?.price.toString()}
            icon={FaDollarSign}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('unit_details.deposit')}
            value={data?.deposit.toString()}
            icon={FaDollarSign}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('unit_details.penalty_percent')}
            value={data?.latePaymentPenaltyPercent.toString() + '%'}
            icon={MdPercent}
          />
        </SimpleGrid>
      </Flex>

      <ExpensesTable
        miniActions
        byUnit
        unit={data}
        title={t('unit_details.expenses')}
        layout={{
          add: true,
          reload: true,
          search: false,
          total: false,
        }}
      />
    </>
  );
};

export default UnitDetails;
