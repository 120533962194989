import { useState } from 'react';
import { Button, Checkbox, Flex, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Popup from '../Popup';

type Props<F> = {
  isOpen: boolean;
  setFiltersOpen: (isOpen: boolean) => void;
  initialFilters: PaginationFilters<F>;
  setFilter: (filters: PaginationFilterData<F>) => void;
};

const TableFilters = <F,>({
  isOpen,
  initialFilters,
  setFiltersOpen,
  setFilter,
}: Props<F>) => {
  const [filters, setFilters] = useState<typeof initialFilters>({ ...initialFilters });

  const { t } = useTranslation();

  const handleCancel = () => {
    setFiltersOpen(false);
  };

  const handleSave = () => {
    const _filters: PaginationFilterData<F> = Object.keys(filters).reduce((acc, key) => {
      acc[key as keyof F] = filters[key as keyof F].values
        .filter(({ selected }) => selected)
        .map(({ value }) => value) as valueof<F>[];

      return acc;
    }, {} as PaginationFilterData<F>);

    setFilter(_filters);
    setFiltersOpen(false);
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setFiltersOpen(false)}
      title={t('common.filters')}
      content={
        <Flex direction="column">
          {Object.keys(filters).map((key) => {
            const title = filters[key as keyof F].title;

            return (
              <Flex key={key} direction="column">
                <Text>{t(title)}</Text>

                {filters[key as keyof F].values.map(
                  ({ tKey, selected, label, value }) => (
                    <Checkbox
                      key={value as string}
                      isChecked={selected}
                      mt="10px"
                      onChange={() => {
                        setFilters((prev) => ({
                          ...prev,
                          [key]: {
                            ...prev[key as keyof F],
                            values: prev[key as keyof F].values.map((v) =>
                              v.value === value ? { ...v, selected: !v.selected } : v,
                            ),
                          },
                        }));
                      }}
                    >
                      {tKey ? t(tKey) : label}
                    </Checkbox>
                  ),
                )}
              </Flex>
            );
          })}
        </Flex>
      }
      footer={
        <HStack>
          <Button type="submit" variant="brand" onClick={handleSave}>
            {t('form.save')}
          </Button>

          <Button variant="outline" onClick={handleCancel}>
            {t('form.cancel')}
          </Button>
        </HStack>
      }
    />
  );
};

export default TableFilters;
