import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Box,
  Portal,
  Link,
} from '@chakra-ui/react';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { CheckIcon, HamburgerIcon } from '@chakra-ui/icons';
import { MdLanguage } from 'react-icons/md';
import { FaEnvelope, FaHospitalUser, FaPaperclip, FaSignOutAlt } from 'react-icons/fa';

import { logout } from '@/utils/app';
import LayoutStore from '@/stores/layout';
import { DOCUMENTATION_URL, GET_HELP_EMAIL, SUPPORTED_LANGUAGES } from '@/constants/app';
import AppStore from '@/stores/app';

type Props = {
  title: string;
};

function HeaderLinks({ title }: Props) {
  const { t, i18n } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const boxShadow = useColorModeValue('0px 5px 5px rgba(112, 144, 176, 0.12)', 'unset');

  const { setMenuOpen } = LayoutStore;
  const { organization } = AppStore;

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap="unset"
      py="10px"
      px="20px"
      pl={{ base: '20px', md: '10px' }}
      borderRadius="30px"
      boxShadow={boxShadow}
    >
      <Box display={{ md: 'none' }} flex={1}>
        <Text fontSize="xl">{title}</Text>
      </Box>

      <Box display={{ xl: 'none' }}>
        <Menu>
          <MenuButton w="40px" mr="5px" onClick={() => setMenuOpen(true)}>
            <Icon mt={0} h="18px" w="18px" color={navbarIcon} as={HamburgerIcon} />
          </MenuButton>
        </Menu>
      </Box>

      <Menu>
        <MenuButton w="40px" onClick={toggleColorMode}>
          <Icon
            mt={2}
            h="18px"
            w="18px"
            color={navbarIcon}
            as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
          />
        </MenuButton>
      </Menu>

      <Menu>
        <MenuButton w="40px" mr="5px">
          <Icon mt={2} h="18px" w="18px" color={navbarIcon} as={MdLanguage} />
        </MenuButton>

        <Portal>
          <MenuList
            boxShadow={boxShadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                {t('navbar.language')}
              </Text>
            </Flex>

            <Flex flexDirection="column" p="10px">
              {Object.values(SUPPORTED_LANGUAGES).map((lang) => (
                <MenuItem
                  key={lang}
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  _active={{ bg: 'none' }}
                  bg="none"
                  borderRadius="8px"
                  px="14px"
                  onClick={() => i18n.changeLanguage(lang)}
                >
                  {i18n.language === lang && (
                    <Icon as={CheckIcon} ml={-3} mr={2} fontSize={10} color="green.400" />
                  )}

                  <Text fontSize="sm">{t(`navbar.${lang}`)}</Text>
                </MenuItem>
              ))}
            </Flex>
          </MenuList>
        </Portal>
      </Menu>

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            name="Adela Parkson"
            bg="brand.400"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>

        <Portal>
          <MenuList
            boxShadow={boxShadow}
            p="10px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              _active={{ bg: 'none' }}
              bg="none"
              color={textColor}
              px="14px"
              cursor="default"
              opacity={0.7}
              borderBottom="1px solid"
              textTransform="capitalize"
              borderColor={borderColor}
              maxW="300px"
              icon={<FaHospitalUser />}
            >
              {organization?.name}
            </MenuItem>
            {/* <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              _active={{ bg: 'none' }}
              bg="none"
              px="14px"
              color={textColor}
              as={Link}
              href={GPT_URL}
              icon={<FaQuestion />}
              target="_blank"
            >
              {t('navbar.get_help')}
            </MenuItem> */}

            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              _active={{ bg: 'none' }}
              bg="none"
              px="14px"
              color={textColor}
              as={Link}
              href={`mailto:${GET_HELP_EMAIL}`}
              icon={<FaEnvelope />}
              target="_blank"
            >
              {t('navbar.contact_support')}
            </MenuItem>

            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              _active={{ bg: 'none' }}
              bg="none"
              px="14px"
              color={textColor}
              icon={<FaPaperclip />}
              as={Link}
              href={DOCUMENTATION_URL}
              target="_blank"
            >
              {t('navbar.documentation')}
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              _active={{ bg: 'none' }}
              bg="none"
              color="red.400"
              px="14px"
              onClick={logout}
              borderTop="1px solid"
              borderColor={borderColor}
              icon={<FaSignOutAlt />}
            >
              {t('navbar.logout')}
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  );
}

export default observer(HeaderLinks);
