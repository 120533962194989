import { useDropzone } from 'react-dropzone';
import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { MdUpload } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

type Props = {
  fileTypes: string[];
  onDrop: (acceptedFiles: File[]) => void;
  disabled?: boolean;
};

const Upload = ({ fileTypes, onDrop, disabled, ...rest }: Props) => {
  const brandColor = useColorModeValue('brand.500', 'white');
  const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  const bg = useColorModeValue('gray.100', 'navy.700');

  const { t } = useTranslation();
  // @ts-ignore
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: fileTypes.reduce((acc, type) => {
      acc[`application/${type}`] = [`.${type}`];
      return acc;
    }, {} as { [key: string]: string[] }),
  });

  return (
    <Flex alignItems="center" minH="220px" {...rest}>
      <Flex
        align="center"
        justify="center"
        bg={bg}
        border="1px dashed"
        borderColor={borderColor}
        borderRadius="16px"
        w="100%"
        h="max-content"
        minH="100%"
        _disabled={{ opacity: 0.5 }}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />

        <Flex direction="column" align="center" justify="center">
          <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />

          <Flex justify="center" mx="auto" mb="12px">
            <Text fontSize="xl" fontWeight="700" color={brandColor}>
              {t('components.upload_files')}
            </Text>
          </Flex>

          <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
            {t('components.file_types_allowed', {
              types: fileTypes.join(', ').toUpperCase(),
            })}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Upload;
