import { useEffect, useState } from 'react';
import {
  Box,
  Input,
  Text,
  Badge,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { FaUser } from 'react-icons/fa';

import useRequestState from '@/hooks/useRequestState';
import { searchClients } from '@/api/client';
import NoData from '@/components/NoData';

const ClientStep = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const requestCriteria = search.length >= 3;

  const {
    control,
    setValue,
    register,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const value = watch('client');

  useEffect(() => {
    clearErrors('client');
  }, [clearErrors, search]);

  useEffect(() => {
    if (!requestCriteria) {
      setValue('client', '');
    }
  }, [requestCriteria, setValue]);

  const { data, loading } = useRequestState<PaginateResult<Client>>(
    () =>
      searchClients({
        page: 1,
        limit: 5,
        sortBy: 'name',
        order: 'asc',
        search,
      }),
    [search],
    { condition: requestCriteria },
  );

  const handleChange = (value: string) => {
    setValue('client', value);
  };

  return (
    <Box minH="300px">
      <InputGroup mb="20px">
        <InputLeftElement pointerEvents="none">
          <FaUser color="gray.300" />
        </InputLeftElement>

        <Input
          placeholder={t('contract_form.client_search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <InputRightElement>
          <Spinner size="sm" visibility={loading ? 'visible' : 'hidden'} />
        </InputRightElement>
      </InputGroup>

      {errors.client?.message && (
        <Text fontSize="sm" mt="10px" color="red.300">
          {errors?.client?.message as string}
        </Text>
      )}

      <Controller
        name="client"
        control={control}
        rules={{ required: t('form.required') }}
        defaultValue={null}
        render={() => (
          <RadioGroup onChange={handleChange} value={value}>
            {data?.docs.length ? (
              <Stack my="20px" w="100%">
                {data.docs.map(({ _id, name, lastName, email }) => (
                  <Radio
                    key={_id}
                    {...register('client', { required: t('form.required') })}
                    value={_id}
                  >
                    <Badge variant="outline" mx="5px" colorScheme="purple">
                      {name} {lastName}
                    </Badge>
                    - {email}
                  </Radio>
                ))}
              </Stack>
            ) : (
              <Box my="10px">
                <NoData />
              </Box>
            )}
          </RadioGroup>
        )}
      />
    </Box>
  );
};

export default ClientStep;
