import { ADMIN_ROLE } from '@/constants/app';

export const DEFAULT_FORM_DATA: UserFormData = {
  _id: '',
  name: '',
  lastName: '',
  email: '',
  role: ADMIN_ROLE.MANAGER,
  properties: [],
};
