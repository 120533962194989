import { mode } from '@chakra-ui/theme-tools';

export const inputStyles = {
  components: {
    Input: {
      defaultProps: {
        variant: 'auth',
      },
      baseStyle: {
        field: {
          borderRadius: '8px',
          fontWeight: '500',
          boxShadow: 'md',
        },
      },
      variants: {
        baseStyle: {
          field: {
            color: mode('navy.700', 'red'),
            bg: mode('transparent', 'transparent'),
          },
        },
        filled: (props: any) => ({
          field: {
            bg: mode('gray.100', 'navy.800')(props),
            color: mode('gray.700', 'white')(props),
            _placeholder: { color: 'secondaryGray.500' },
            outline: 'none',
            border: 'none',
          },
        }),
        search: (props: any) => ({
          field: {
            bg: mode('white', 'navy.800')(props),
            color: mode('gray.700', 'white')(props),
            _placeholder: { color: 'secondaryGray.500' },
            borderRadius: '20px',
          },
        }),
        main: (props: any) => ({
          field: {
            bg: mode('transparent', 'navy.800')(props),
            border: '1px solid',
            color: mode('secondaryGray.900', 'white')(props),
            borderColor: mode('secondaryGray.100', 'whiteAlpha.100')(props),

            fontSize: 'sm',
            p: '20px',
            _placeholder: { color: 'secondaryGray.400' },
          },
        }),
        auth: (props: any) => ({
          field: {
            fontWeight: '500',
            color: mode('navy.700', 'white')(props),
            bg: mode('transparent', 'transparent')(props),
            border: '1px solid',
            borderColor: mode('secondaryGray.100', 'rgba(135, 140, 189, 0.3)')(props),
            _placeholder: { color: 'secondaryGray.600', fontWeight: '400' },
          },
        }),
        authSecondary: () => ({
          field: {
            bg: 'transparent',
            border: '1px solid',
            borderColor: 'secondaryGray.100',
            _placeholder: { color: 'secondaryGray.600' },
          },
        }),
      },
    },
    NumberInput: {
      baseStyle: {
        field: {
          fontWeight: 400,
        },
      },

      variants: {
        main: () => ({
          field: {
            bg: 'transparent',
            border: '1px solid',

            borderColor: 'secondaryGray.100',
            _placeholder: { color: 'secondaryGray.600' },
          },
        }),
        auth: () => ({
          field: {
            bg: 'transparent',
            border: '1px solid',

            borderColor: 'secondaryGray.100',
            _placeholder: { color: 'secondaryGray.600' },
          },
        }),
        authSecondary: () => ({
          field: {
            bg: 'transparent',
            border: '1px solid',

            borderColor: 'secondaryGray.100',
            _placeholder: { color: 'secondaryGray.600' },
          },
        }),
        search: () => ({
          field: {
            border: 'none',
            py: '11px',
            borderRadius: 'inherit',
            _placeholder: { color: 'secondaryGray.600' },
          },
        }),
      },
    },
    Select: {
      baseStyle: (props: any) => ({
        field: {
          borderRadius: '8px',
          fontWeight: '500',
          color: mode('navy.700', 'white')(props),
          _placeholder: { color: 'secondaryGray.600', fontWeight: '400' },
          background: 'transparent',
          border: '1px solid',
          borderColor: mode('secondaryGray.100', 'rgba(135, 140, 189, 0.3)')(props),
        },
      }),
      defaultProps: {
        variant: 'main',
      },
      variants: {
        main: (props: any) => ({
          field: {
            borderRadius: '8px',
            fontWeight: '500',
            boxShadow: 'md',
          },
        }),
      },
    },
  },
};
