import * as XLSX from 'xlsx';

import logger from './logger';

export const parseExcel = async <T>(files: File[]): Promise<T[] | null> => {
  try {
    const file = files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: 'array' });
    const sheet = workbook.Sheets[workbook.SheetNames[0]];

    return XLSX.utils.sheet_to_json(sheet) as T[];
  } catch (error) {
    logger('ERROR', error);

    return null;
  }
};

export const parseDate = (excelDate: number): Date => {
  const date = new Date((excelDate - (25567 + 1)) * 86400 * 1000);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
