import 'react-json-view-lite/dist/index.css';

import { memo, useState } from 'react';
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';

import {
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  Text,
  Link,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import usePageInfo from '@/hooks/usePageInfo';
import { importUnit, importClient } from '@/api/import';
import { RiImportFill } from 'react-icons/ri';

import Upload from '@/components/UploadZone';
import { parseDate, parseExcel } from '@/utils/parse';
import useRequestState from '@/hooks/useRequestState';
import unitSample from './samples/unit.xlsx';
import clientSample from './samples/client.xlsx';
import { SUPPORTED_LANGUAGES } from '@/constants/app';
import { DEFAULT_TOAST_OPTIONS } from '@/constants/ui';
import logger from '@/utils/logger';

const MAP_MODEL = {
  unit: {
    api: importUnit,
    example: unitSample,
  },
  client: {
    api: importClient,
    example: clientSample,
  },
};

const ImportPage = () => {
  usePageInfo({ title: 'pages.import_export' });

  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const toast = useToast();

  const [model, setModel] = useState<keyof typeof MAP_MODEL>('unit');
  const [json, setJson] = useState<unknown[] | null>(null);

  const { trigger: submitTrigger, loading } = useRequestState<'ok'>(
    () => {
      const api = MAP_MODEL[model].api;

      return api(json!);
    },
    [json],
    { condition: false },
  );

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setModel(e.target.value as keyof typeof MAP_MODEL);
    setJson(null);
  };

  const handleFileUpload = async (files: File[]) => {
    const data = await parseExcel(files);

    if (!data) return;

    const hasErrors = model === 'unit' ? verifyUnit(data) : verifyClient(data);

    if (hasErrors) {
      setJson(null);

      return toast({
        ...DEFAULT_TOAST_OPTIONS,
        title: t('import_form.errors'),
        description: t('import_form.errors_in_data_for', { model: 'unit' }),
      });
    }

    setJson(model === 'unit' ? data : mapClient(data as ImportedClient[]));
  };

  return (
    <SimpleGrid
      columns={{ base: 1, lg: 2, xl: 3, '3xl': 4 }}
      spacing={4}
      maxH="100%"
      pb="20px"
    >
      <Flex direction="column" gridColumn={{ base: 1, lg: 1, xl: 1, '3xl': 1 }}>
        <Flex direction="row" align="center" mb="15px" pl="15px">
          <Icon as={RiImportFill} mr="15px" fontSize="2xl" />

          <Text fontWeight="bold" fontSize="2xl">
            {t('import_form.import')}
          </Text>
        </Flex>

        <Card>
          <FormControl>
            <FormLabel>{t('import_form.data_type')}</FormLabel>

            <Select onChange={handleSelect} value={model} disabled={loading}>
              {Object.keys(MAP_MODEL).map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </Select>
          </FormControl>

          <Button
            as={Link}
            href={MAP_MODEL[model]?.example}
            target="_blank"
            size="lg"
            my="20px"
            isExternal
          >
            {t('import_form.download_template')}
          </Button>

          <Upload disabled={loading} onDrop={handleFileUpload} fileTypes={['xlsx']} />

          <Button
            mt="20px"
            size="lg"
            type="button"
            variant="brand"
            isLoading={loading}
            disabled={!json || loading}
            onClick={submitTrigger}
          >
            {t('import_form.submit')}
          </Button>
        </Card>
      </Flex>

      <Flex
        gridColumn={{ base: 1, lg: 'span 2', xl: 'span 2', '3xl': 'span 3' }}
        direction="column"
        w="100%"
        flex="1"
      >
        <Flex direction="row" align="center" mb="15px" pl="15px">
          <Icon as={RiImportFill} mr="15px" fontSize="2xl" />

          <Text fontWeight="bold" fontSize="2xl">
            {t('import_form.import_preview')}
          </Text>
        </Flex>

        <Card w="100%" h="490px" overflowY="auto">
          {json && (
            <JsonView
              data={json}
              shouldExpandNode={allExpanded}
              style={{
                ...(colorMode === 'dark' ? darkStyles : defaultStyles),
                container: 'red',
              }}
            />
          )}
        </Card>
      </Flex>
    </SimpleGrid>
  );
};

type ImportedClient = {
  name: string;
  lastName: string;
  dob: number;
  email: string;
  phone: string;
  language: Languaes;
  doc_type: string;
  doc_number: string;
  doc_issued: number;
  doc_expires: number;
};

const mapClient = (data: ImportedClient[]) =>
  data.map((client) => ({
    name: client.name,
    lastName: client.lastName,
    dob: parseDate(client.dob),
    email: client.email,
    phone: client.phone,
    language: Object.values(SUPPORTED_LANGUAGES).includes(client.language)
      ? client.language
      : 'en',
    document: {
      type: client.doc_type,
      number: client.doc_number,
      issued: parseDate(client.doc_issued),
      expires: parseDate(client.doc_expires),
    },
  }));

export const verifyUnit = (data: any[]) => {
  return !!data
    .map((unit: UnitFormData, i) => {
      const errors: string[] = [];

      if (!unit.name) errors.push('name');
      if (!unit.price) errors.push('price');
      if (!unit.deposit) errors.push('deposit');
      if (!unit.latePaymentPenaltyPercent) errors.push('percent');
      if (!unit.comments) errors.push('comments');

      if (!!errors.length) {
        logger('ERROR', 'unit', i, errors);
      }

      return !!errors.length;
    })
    .find((error) => !!error);
};

export const verifyClient = (data: any[]) => {
  return !!data
    .map((client: ImportedClient, i) => {
      const errors: string[] = [];

      if (!client.name) errors.push('name');
      if (!client.lastName) errors.push('lastName');
      if (!client.dob) errors.push('dob');
      if (!client.email) errors.push('email');
      if (!client.phone) errors.push('phone');
      if (
        !client.language ||
        !Object.values(SUPPORTED_LANGUAGES).includes(client.language as any)
      )
        errors.push('language');
      if (!client.doc_type) errors.push('doc_type');
      if (!client.doc_number) errors.push('doc_number');
      if (!client.doc_issued) errors.push('doc_issued');
      if (!client.doc_expires) errors.push('doc_expires');

      if (!!errors.length) {
        logger('ERROR', 'client', i, errors);
      }

      return !!errors.length;
    })
    .find((error) => !!error);
};

export default memo(ImportPage);
