import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Skeleton,
  Card,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { IconType } from 'react-icons';

import NoData from '@/components/NoData';
import IconBox from '@/components/Icons/IconBox';
import { getNestedValue } from '@/utils/js';

interface ColumnConfig<T, E> {
  id: keyof T | E;
  header: string;
  accessor: string | null;
  maxW?: string;
  center?: boolean;
  isSortable?: boolean;
  cell?: (row: T) => JSX.Element;
}

interface DataTableProps<T, E> {
  data: T[];
  search?: JSX.Element;
  actions?: { onClick?: () => void; icon: IconType; redDot?: boolean }[];
  columns: ColumnConfig<T, E>[];
  title?: string;
  sortBy?: string;
  loading?: boolean;
  order?: 'asc' | 'desc';
  pagination?: JSX.Element;
  numerable?: boolean;
  mt?: number | string;
  onSort?: (column: keyof T) => void;
  onRowClick?: (row: T) => void;
}

export default function DataTable<T extends object, E = 'actions'>({
  data,
  columns,
  title,
  order,
  sortBy,
  loading = false,
  pagination,
  actions,
  search,
  numerable = true,
  mt,
  onSort,
  onRowClick,
}: DataTableProps<T, E>) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const menuBg = useColorModeValue('white', 'navy.800');
  const miniActionColor = useColorModeValue('gray.500', 'whiteAlpha.700');

  const placeholderRowCount = 10;

  const handleClickRow = (column: ColumnConfig<T, E>) => {
    const id = column.id as keyof T;

    if (column.isSortable && onSort) {
      onSort(id);
    }
  };

  return (
    <Flex direction="column" flex={1} overflow="hidden" mt={mt}>
      <Card
        flex={1}
        flexDirection="column"
        w="100%"
        px={3}
        py={title ? 'auto' : '0px'}
        pt={title ? 'auto' : '5px'}
      >
        <Box flex={1} flexDirection="column" w="100%" px="10px" overflow="auto">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            height="20px"
            mb="10px"
          >
            {title && (
              <Flex>
                <Text
                  color={textColor}
                  fontSize={{ sm: '18px', lg: '22px' }}
                  fontWeight="700"
                >
                  {title}
                </Text>
              </Flex>
            )}

            {(actions || search) && (
              <Flex>
                {search && search}

                {actions &&
                  actions.map(({ icon, onClick, redDot }, index) => (
                    <Box position="relative" key={index}>
                      <IconBox
                        w="35px"
                        h="35px"
                        ml={3}
                        _hover={{ cursor: 'pointer' }}
                        onClick={onClick}
                        icon={<Icon as={icon} color={miniActionColor} fontSize={25} />}
                      />

                      {redDot && (
                        <Box
                          position="absolute"
                          top="8px"
                          right="7px"
                          w="7px"
                          h="7px"
                          borderRadius="50%"
                          bg="red.500"
                          zIndex={1}
                        />
                      )}
                    </Box>
                  ))}
              </Flex>
            )}
          </Flex>

          <Table variant="simple" minH={data.length === 0 ? '100%' : 'auto'} flex={1}>
            <Thead position="sticky" top="0" zIndex="1" bg={menuBg}>
              <Tr>
                {numerable && (
                  <Th
                    px={4}
                    width="10px"
                    borderColor={borderColor}
                    textAlign="center"
                    color="gray.400"
                  >
                    #
                  </Th>
                )}

                {columns.map((column) => (
                  <Th
                    key={column.id as string}
                    px={4}
                    borderColor={borderColor}
                    userSelect="none"
                    cursor={column.isSortable ? 'pointer' : 'default'}
                    textAlign={column.center ? 'center' : 'left'}
                    onClick={() => handleClickRow(column)}
                  >
                    <Flex
                      align="center"
                      gap={1}
                      justify={column.center ? 'center' : 'flex-start'}
                    >
                      <Text
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                        textAlign={column.center ? 'center' : 'left'}
                      >
                        {column.header}
                      </Text>

                      {getSortIcon(column, sortBy, order)}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {loading ? (
                [...Array(placeholderRowCount)].map((_, index) => (
                  <Tr key={index}>
                    {columns.map((column) => (
                      <Td key={column.id as string} px={4}>
                        <Skeleton
                          height="15px"
                          borderRadius={6}
                          startColor="navy.200"
                          endColor="gray.200"
                        />
                      </Td>
                    ))}
                  </Tr>
                ))
              ) : data.length > 0 ? (
                data.map((row, rowIndex) => (
                  <Tr
                    key={rowIndex}
                    onClick={onRowClick ? () => onRowClick(row) : undefined}
                    transition="all 0.2s ease"
                    _hover={{
                      bg: onRowClick ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
                      cursor: onRowClick ? 'pointer' : 'default',
                    }}
                  >
                    {numerable && (
                      <Td
                        px={4}
                        fontSize="md"
                        fontWeight={500}
                        borderColor="transparent"
                        color={textColor}
                        textAlign="center"
                      >
                        {rowIndex + 1}
                      </Td>
                    )}

                    {columns.map((column) => (
                      <Td
                        key={column.id as string}
                        px={4}
                        fontSize="md"
                        fontWeight={500}
                        borderColor="transparent"
                        color={textColor}
                        textAlign={column.center ? 'center' : 'left'}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {column.cell ? (
                          column.cell(row)
                        ) : (
                          <Tooltip
                            label={
                              !!column.maxW &&
                              getNestedValue(row, column.accessor as string)
                            }
                          >
                            <Text isTruncated={!!column.maxW} w={column.maxW}>
                              {column.accessor
                                ? getNestedValue(row, column.accessor as string)
                                : null}
                            </Text>
                          </Tooltip>
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={columns.length + 1} border="none">
                    <NoData />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </Card>

      {pagination && pagination}
    </Flex>
  );
}

const getSortIcon = (
  column: ColumnConfig<any, any>,
  sortBy?: DataTableProps<any, any>['sortBy'],
  order?: DataTableProps<any, any>['order'],
) => {
  if (!column.isSortable) return null;

  if (sortBy === column.id) {
    return order === 'asc' ? (
      <TriangleUpIcon fontSize={8} />
    ) : (
      <TriangleDownIcon fontSize={8} />
    );
  }

  return (
    <Flex direction="column" justifyContent="center" alignItems="center" opacity="0.5">
      <TriangleUpIcon fontSize={8} />
      <TriangleDownIcon fontSize={8} />
    </Flex>
  );
};
