import { useCallback, useState } from 'react';
import {
  Box,
  Card,
  Flex,
  Icon,
  IconButton,
  SimpleGrid,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsStripe } from 'react-icons/bs';
import { MdVerified } from 'react-icons/md';
import { FaPlusCircle, FaSync } from 'react-icons/fa';
import { TbTrash } from 'react-icons/tb';

import { getStripeList, stripeVerifyList, removeStripeAcc } from '@/api/stripe';
import usePageInfo from '@/hooks/usePageInfo';
import AddStripeAccount from './components/AddStripeAccount';
import useRequestState from '@/hooks/useRequestState';
import NoData from '@/components/NoData';

const SettingsPage = () => {
  usePageInfo({ title: 'pages.settings' });

  const [stripePopupOpen, setStripePopupOpen] = useState(false);
  const [data, setData] = useState<StripeAccountList>([]);

  const { t } = useTranslation();

  const { trigger: listTrigger, loading: listLoading } =
    useRequestState<StripeAccountList>(() => getStripeList(), [], { onSuccess: setData });

  const { trigger: removeTrigger, loading: removeLoading } = useRequestState<'ok'>(
    (id) => removeStripeAcc(id as string),
    [],
    {
      condition: false,
      onSuccess: listTrigger,
    },
  );

  const { trigger: stripeRefresh, loading: verifyLoading } =
    useRequestState<StripeAccountList>(() => stripeVerifyList(), [], {
      condition: false,
      onSuccess: setData,
    });

  const handleClose = useCallback(() => {
    setStripePopupOpen(false);

    listTrigger();
  }, [setStripePopupOpen, listTrigger]);

  const loading = listLoading || verifyLoading || removeLoading;

  return (
    <>
      <SimpleGrid columns={{ base: 1, lg: 1, xl: 1, '3xl': 2 }} spacing={4}>
        <Flex direction="column">
          <Flex direction="row" align="center" mb="15px" pl="15px">
            <Icon as={BsStripe} mr="15px" fontSize="2xl" />

            <Text fontWeight="bold" fontSize="2xl">
              {t('settings.stripe.stripe_accounts')}
            </Text>

            <Flex ml="auto" direction="row" gap="10px">
              <IconButton
                ml="auto"
                disabled={loading}
                aria-label="Refresh Stripe Accounts"
                icon={verifyLoading ? <Spinner size="sm" /> : <Icon as={FaSync} />}
                onClick={stripeRefresh}
              />

              <IconButton
                ml="auto"
                disabled={loading}
                aria-label="Add Stripe Account"
                icon={<Icon as={FaPlusCircle} />}
                onClick={() => setStripePopupOpen(true)}
              />
            </Flex>
          </Flex>

          <Card>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>{t('settings.stripe.name')}</Th>
                  <Th>{t('settings.stripe.country')}</Th>
                  <Th>{t('settings.stripe.currency')}</Th>
                  <Th>{t('settings.stripe.email')}</Th>
                  <Th w="80px">{t('settings.stripe.verified')}</Th>
                  <Th w="80px">{t('settings.stripe.actions')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map(
                  ({ name, account, verified, _id, country, currency, email }) => (
                    <Tr key={account} mb="5px" mr="5px">
                      <Td>
                        <Text isTruncated maxW="100%">
                          {name}
                        </Text>
                      </Td>
                      <Td>{country}</Td>
                      <Td>{currency}</Td>
                      <Td>
                        <Text isTruncated maxW="100%">
                          {email}
                        </Text>
                      </Td>
                      <Td textAlign="center">
                        <Icon
                          as={MdVerified}
                          color={verified ? 'green.400' : 'red.400'}
                        />
                      </Td>
                      <Td textAlign="center">
                        <IconButton
                          aria-label="remove"
                          onClick={() => removeTrigger(_id)}
                          icon={<TbTrash />}
                          disabled={loading}
                        />
                      </Td>
                    </Tr>
                  ),
                )}
              </Tbody>
            </Table>

            {!data.length && (
              <Box mb="40px" mt="50px">
                <NoData />
              </Box>
            )}
          </Card>
        </Flex>
      </SimpleGrid>

      <AddStripeAccount onClose={handleClose} isOpen={stripePopupOpen} />
    </>
  );
};

export default SettingsPage;
