import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Avatar, Flex, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getTasks, reorderTasks } from '@/api/task';
import { getUsers } from '@/api/admin';
import usePageInfo from '@/hooks/usePageInfo';
import useRequestState from '@/hooks/useRequestState';
import Column from './components/Column';
import handleDragEnd from '@/utils/tasks';
import CreateTask from './components/CreateTask';
import AppStore from '@/stores/app';

const TaskPage = () => {
  usePageInfo({ title: 'pages.tasks' });

  const { t } = useTranslation();
  const { selectedProperty } = AppStore;

  const [seleted, setSelected] = useState<Partial<Task> | null | undefined>(undefined);
  const [assignee, setAssignee] = useState<Admin | null | undefined>(undefined);

  const { data: admins } = useRequestState<PaginateResult<Admin>>(
    () => getUsers({ page: 1, limit: 1000, sortBy: 'lastName', order: 'asc' }),
    [],
  );

  const { trigger: reorderTrigger } = useRequestState<'ok'>(
    (data) => reorderTasks(data as ReorderedTask[]),
    [],
    { condition: false },
  );

  const {
    data,
    trigger: dataTrigger,
    setData,
  } = useRequestState<TaskListResponse>(() => {
    return getTasks(assignee ? assignee._id : assignee);
  }, [assignee, selectedProperty]);

  const handleTaskCreate = (data: Partial<Task>) => {
    setSelected({ ...data });
  };

  const onSubmit = () => {
    setSelected(undefined);
    dataTrigger();
  };

  const mappedAdmin = useMemo(() => {
    return admins?.docs.reduce((acc, admin) => {
      acc[admin._id] = admin;

      return acc;
    }, {} as Record<string, Admin>);
  }, [admins]);

  const onDragEnd = (result: DropResult) => {
    const { updatedTasks, reorderedItems } = handleDragEnd(result, data!);

    setData(updatedTasks);
    reorderTrigger(reorderedItems);
  };

  const onAssineedClick = (admin: Admin | null) => {
    if (admin === null) {
      if (assignee === null) {
        return setAssignee(undefined);
      }
    } else if (assignee?._id === admin._id) {
      return setAssignee(undefined);
    }

    setAssignee(admin);
  };

  return (
    <>
      <Flex>
        <Tooltip label={t('tasks.not_assigned')}>
          <Avatar
            showBorder
            cursor="pointer"
            size="sm"
            name={t('tasks.not_assigned')}
            onClick={() => onAssineedClick(null)}
            mr="-5px"
            bg="gray.500"
            transition="border-color 0.3s"
            borderColor={assignee === null ? 'brand.400' : undefined}
          />
        </Tooltip>

        {admins?.docs.map((admin) => (
          <Tooltip key={admin._id} label={`${admin.name} ${admin.lastName}`}>
            <Avatar
              showBorder
              cursor="pointer"
              size="sm"
              name={`${admin.name} ${admin.lastName}`}
              onClick={() => onAssineedClick(admin)}
              mr="-5px"
              transition="border-color 0.3s"
              borderColor={assignee?._id === admin._id ? 'brand.400' : undefined}
            />
          </Tooltip>
        ))}
      </Flex>

      <DragDropContext onDragEnd={onDragEnd}>
        <Flex gap="20px" py="20px" borderRadius={10} h="100%" overflowX="auto">
          {Object.entries(data || []).map(([status, tasks]) => (
            <Column
              key={status}
              id={status as TaskStatus}
              admins={mappedAdmin}
              onTaskCreate={handleTaskCreate}
              title={t(`tasks.statuses.${status}`)}
              tasks={tasks}
            />
          ))}
        </Flex>
      </DragDropContext>

      <CreateTask
        admins={mappedAdmin}
        data={seleted}
        setSelected={setSelected}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default observer(TaskPage);
