import { mode } from '@chakra-ui/theme-tools';

export const textareaStyles = {
  components: {
    Textarea: {
      baseStyle: {
        borderRadius: '8px',
        boxShadow: 'md',
        background: 'transparent',
        fontWeight: '500',
        border: '1px solid',
        _placeholder: { color: 'secondaryGray.600', fontWeight: '400' },
      },

      variants: {
        main: (props: any) => ({
          color: mode('navy.700', 'white')(props),
          borderColor: mode('secondaryGray.100', 'rgba(135, 140, 189, 0.3)')(props),
        }),
      },

      defaultProps: {
        variant: 'main',
      },
    },
  },
};
