import {
  CURRENCY,
  PAYMENT_CIRCLE,
  PAYMENT_SHIFT_DAY,
  WEEK_DAYS_NUMBER,
} from '@/constants/app';
import { calculateShiftDate, createUtcDate, formatDate } from '@/utils/date';
import {
  Stack,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tooltip,
  Text,
  Input,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useRequestState from '@/hooks/useRequestState';
import { getStripeList } from '@/api/stripe';

const PropertyPayments = () => {
  const { t } = useTranslation();

  const {
    watch,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<PropertyFormData>();

  const paymentShiftValue = watch('paymentShiftDay');
  const _isManualInvoicing = watch('isManualInvoicing');
  const circle = watch('paymentCircle');
  const stripe = watch('stripe');

  // @ts-ignore
  const isManualInvoicing = _isManualInvoicing === 'true' || _isManualInvoicing === true;
  const isMonthly = circle === PAYMENT_CIRCLE.MONTHLY;
  const isWeekly = circle === PAYMENT_CIRCLE.WEEKLY;

  const { data: stripeData, loading: stripeLoading } = useRequestState<StripeAccountList>(
    getStripeList,
    [],
    {
      onSuccess: (data) => {
        const noStripe = stripe === '';

        if (data.length) {
          if (noStripe) {
            setValue('stripe', data[0]._id);
          } else if (!data.find((s) => s._id === stripe)) {
            setValue('stripe', '');
          }
        }
      },
    },
  );

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction="row">
        {/* Currency */}
        <FormControl isRequired isInvalid={!!errors.currency}>
          <FormLabel>{t('property_form.currency')}</FormLabel>

          <Select
            placeholder={t('property_form.select_currency')}
            {...register('currency', { required: t('form.required') })}
          >
            {Object.values(CURRENCY).map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </Select>

          <FormErrorMessage>
            <>{errors.currency?.message}</>
          </FormErrorMessage>
        </FormControl>

        {/* Stripe */}
        <FormControl isRequired isInvalid={!!errors.stripe}>
          <FormLabel>{t('property_form.stripe_accounts')}</FormLabel>

          <Select
            {...register('stripe', { required: t('form.required') })}
            disabled={stripeLoading}
            value={stripe}
            onChange={(e) => {
              setValue('stripe', e.target.value);
            }}
          >
            {stripeData?.length ? (
              stripeData?.map((stripe) => (
                <option key={stripe._id} value={stripe._id}>
                  {stripe.name}
                </option>
              ))
            ) : (
              <option value="">{t('property_form.no_stripe_accounts')}</option>
            )}
          </Select>

          <FormErrorMessage>
            <>{errors.stripe?.message}</>
          </FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex gap={{ base: 4, md: 2 }} direction="row">
        {/* Manual invoicing */}
        <FormControl isRequired>
          <FormLabel>{t('properties.manual_invoicing')}</FormLabel>

          <Select {...register('isManualInvoicing')}>
            <option value="true">{t('common.yes')}</option>
            <option value="false">{t('common.no')}</option>
          </Select>
        </FormControl>

        {/* Invoice expiration */}
        <FormControl isRequired isInvalid={!!errors.invoiceExpirationDays}>
          <FormLabel>{t('property_form.invoice_expiration_days')}</FormLabel>

          <Input
            type="number"
            {...register('invoiceExpirationDays', { required: t('form.required') })}
          />

          <FormErrorMessage>{errors.invoiceExpirationDays?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      {!isManualInvoicing && (
        <Flex gap={{ base: 4, md: 2 }} direction="row">
          <FormControl isRequired isInvalid={!!errors.paymentCircle}>
            <FormLabel>{t('properties.payment_circle')}</FormLabel>

            <Select {...register('paymentCircle', { required: t('form.required') })}>
              {Object.values(PAYMENT_CIRCLE).map((circle) => (
                <option key={circle} value={circle}>
                  {t(`properties.payment_circles.${circle}`)}
                </option>
              ))}
            </Select>

            <FormErrorMessage>
              <>{errors.paymentCircle?.message}</>
            </FormErrorMessage>
          </FormControl>

          {/* Payment Term */}
          {isMonthly && (
            <FormControl isRequired isInvalid={!!errors.paymentShiftDay} px={2}>
              <FormLabel>{t('property_form.payment_term')}</FormLabel>

              <Slider
                min={PAYMENT_SHIFT_DAY.MIN}
                max={PAYMENT_SHIFT_DAY.MAX}
                value={paymentShiftValue}
                onChange={(val) => setValue('paymentShiftDay', val)}
              >
                <Flex position="relative" w="100%" mt={5}>
                  <SliderMark value={PAYMENT_SHIFT_DAY.MIN}>
                    {PAYMENT_SHIFT_DAY.MIN}
                  </SliderMark>

                  <SliderMark
                    value={PAYMENT_SHIFT_DAY.DEFAULT}
                    transform="translateX(-4px)"
                  >
                    {PAYMENT_SHIFT_DAY.DEFAULT}
                  </SliderMark>

                  <SliderMark value={PAYMENT_SHIFT_DAY.MAX} transform="translateX(-100%)">
                    {PAYMENT_SHIFT_DAY.MAX}
                  </SliderMark>
                </Flex>

                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>

                <Tooltip
                  hasArrow
                  isOpen
                  bg="brand.500"
                  color="white"
                  placement="top"
                  label={`${paymentShiftValue}`}
                >
                  <SliderThumb />
                </Tooltip>
              </Slider>

              <FormErrorMessage>{errors.paymentShiftDay?.message}</FormErrorMessage>
            </FormControl>
          )}

          {isWeekly && (
            <FormControl isRequired isInvalid={!!errors.paymentShiftWeekDay}>
              <FormLabel>{t('properties.payment_shift_weekly')}</FormLabel>

              <Select
                {...register('paymentShiftWeekDay', {
                  required: t('form.required'),
                  setValueAs: (val) => Number(val),
                })}
              >
                {WEEK_DAYS_NUMBER.map((day) => (
                  <option key={day} value={day}>
                    {t(`week_days_by_number.${day}`)}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>
                <>{errors.paymentShiftWeekDay?.message}</>
              </FormErrorMessage>
            </FormControl>
          )}
        </Flex>
      )}

      {isMonthly && !isManualInvoicing && (
        <>
          <Flex direction="column" mt="10px">
            <Text fontWeight="bold" textAlign="center">
              {t('property_form.payment_shift_example')}:
            </Text>

            <Flex direction="row" gap="20px" justify="center">
              <Flex>{shiftedDifferentMonths(paymentShiftValue).january}</Flex>
              <Flex>{shiftedDifferentMonths(paymentShiftValue).february}</Flex>
              <Flex>{shiftedDifferentMonths(paymentShiftValue).april}</Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Stack>
  );
};

const shiftedDifferentMonths = (shiftDay: number) => {
  const january = calculateShiftDate(createUtcDate('01-01-2023'), shiftDay);
  const february = calculateShiftDate(createUtcDate('02-01-2023'), shiftDay);
  const april = calculateShiftDate(createUtcDate('04-01-2023'), shiftDay);

  return {
    february: formatDate(february, 'DD MMMM'),
    january: formatDate(january, 'DD MMMM'),
    april: formatDate(april, 'DD MMMM'),
  };
};

export default PropertyPayments;
