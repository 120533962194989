import { request } from '@/utils/request';
import { DEFAULT_PAGINATION_OPTIONS } from '@/constants/app';

export const getProperties = async (
  query: PaginationRequestType = DEFAULT_PAGINATION_OPTIONS,
) =>
  request<PaginateResult<Property>>({
    url: '/property',
    query,
  });

export const createProperty = async (body: PropertyFormData) =>
  request<Property>({
    url: '/property',
    method: 'POST',
    body,
  });

export const updateProperty = async (body: PropertyFormData) =>
  request<Property>({
    url: '/property',
    method: 'PUT',
    body: {
      ...body,
      isManualInvoicing:
        // @ts-ignore
        body.isManualInvoicing === 'true' || body.isManualInvoicing === true,
    },
    param: body._id,
  });

export const deleteProperty = async (id: string) =>
  request<'ok'>({
    url: '/property',
    method: 'DELETE',
    param: id,
  });
