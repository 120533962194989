import { request } from '@/utils/request';
import { DEFAULT_PAGINATION_OPTIONS } from '@/constants/app';

export const getExpenses = async (
  query: PaginationRequestType = DEFAULT_PAGINATION_OPTIONS,
  unit?: string,
) =>
  request<PaginateResult<Expense>>({
    url: '/expense',
    query: { ...query, unit },
  });

export const createExpense = async (body: ExpenseFormData) =>
  request<'ok'>({
    url: '/expense',
    method: 'POST',
    body,
  });

export const updateExpense = async (id: string, body: ExpenseFormData) =>
  request<'ok'>({
    url: '/expense',
    method: 'PUT',
    body,
    param: id,
  });

export const deleteExpense = async (id: string) =>
  request<'ok'>({
    url: '/expense',
    method: 'DELETE',
    param: id,
  });
