import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { FaBell, FaPencilAlt, FaTrash, FaUsers } from 'react-icons/fa';
import { IoReload, IoAdd } from 'react-icons/io5';

import DataTable from '@/components/Table';
import { getAnnouncements, deleteAnnouncement, notifyUsers } from '@/api/announcement';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import Form from './components/form';
import { formatDate } from '@/utils/date';
import ActionBar from '@/components/ActionBar';
import { DEFAULT_TOAST_OPTIONS } from '@/constants/ui';
import AppStore from '@/stores/app';

const AnnouncementsPage = () => {
  usePageInfo({ title: 'pages.announcements' });

  const { t } = useTranslation();
  const toast = useToast();
  const { selectedProperty } = AppStore;

  const [selectedAnnouncement, setSelectedAnnouncement] = useState<
    Announcement | null | undefined
  >(undefined);

  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Announcement>({
    initialSortBy: 'createdAt',
    initialOrder: 'desc',
  });

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Announcement>>(
    () => getAnnouncements({ page, limit, sortBy, order, search }),
    [page, limit, sortBy, order, search, selectedProperty],
  );

  const { trigger: deleteTrigger } = useRequestState<'ok'>(
    (_id) => deleteAnnouncement(_id as Announcement['_id']),
    [],
    { condition: false, onSuccess: dataTrigger },
  );

  const onSuccessNotify = useCallback(() => {
    toast({
      ...DEFAULT_TOAST_OPTIONS,
      title: t('announcements.notify_success'),
      status: 'success',
    });

    dataTrigger();
  }, [dataTrigger, t, toast]);

  const { trigger: notifyTrigger } = useRequestState<'ok'>(
    (data) => notifyUsers(data as Announcement['_id']),
    [],
    {
      condition: false,
      onSuccess: onSuccessNotify,
    },
  );

  const handleSubmit = useCallback(() => {
    setSelectedAnnouncement(undefined);
    dataTrigger();
  }, [dataTrigger]);

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          setSearch={setSearch}
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('announcements.total')}
              value={`${data?.totalDocs ?? '-'}`}
              icon={FaUsers}
            />
          }
          actions={
            <>
              <ActionButton
                mini
                icon={IoAdd}
                onClick={() => setSelectedAnnouncement(null)}
              />

              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<Announcement>
          mt="20px"
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'name',
              header: t('announcements.name'),
              accessor: 'name',
              isSortable: true,
            },
            {
              id: 'message',
              header: t('announcements.message'),
              accessor: 'message',
              isSortable: true,
            },
            {
              id: 'sent',
              header: t('announcements.sent'),
              accessor: 'sent',
              center: true,
              isSortable: true,
              cell: (data: Announcement) => (
                <Badge colorScheme={data.sent ? 'green' : 'red'}>
                  {data.sent ? t('common.yes') : t('common.no')}
                </Badge>
              ),
            },
            {
              id: 'createdAt',
              header: t('units.created_at'),
              accessor: 'createdAt',
              isSortable: true,
              cell: (data: Announcement) => <Text>{formatDate(data.createdAt)}</Text>,
            },
            {
              id: 'actions',
              header: t('announcements.actions'),
              accessor: null,
              center: true,
              cell: (data: Announcement) => (
                <HStack justify="center">
                  <Tooltip
                    aria-label="Edit"
                    label={
                      data.sent
                        ? t('announcements.edit_announcement_info')
                        : t('announcements.edit_announcement')
                    }
                  >
                    <IconButton
                      aria-label="Edit"
                      size="sm"
                      disabled={data.sent}
                      icon={<FaPencilAlt />}
                      onClick={() => setSelectedAnnouncement(data)}
                    />
                  </Tooltip>

                  <Tooltip
                    aria-label="Delete"
                    label={
                      data.sent
                        ? t('announcements.notify_client_already')
                        : t('announcements.notify_clients')
                    }
                  >
                    <IconButton
                      aria-label="Notify"
                      size="sm"
                      icon={<FaBell />}
                      disabled={data.sent}
                      onClick={() => notifyTrigger(data._id)}
                    />
                  </Tooltip>

                  <Tooltip
                    aria-label="Delete"
                    label={
                      data.sent ? t('announcements.delete_announcement_info') : undefined
                    }
                  >
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<FaTrash />}
                      disabled={data.sent}
                      onClick={() => deleteTrigger(data._id)}
                    />
                  </Tooltip>
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <Form
        data={selectedAnnouncement}
        setSelected={setSelectedAnnouncement}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(AnnouncementsPage);
