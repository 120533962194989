import { observer } from 'mobx-react-lite';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import AuthStore from '@/stores/auth';
import { ROUTES } from '@/constants/routes';
import AuthLayout from '@/containers/AuthLayout';
import AdminLayout from '@/containers/AdminLayout';
import LoginPage from '@/views/Login';
import HomePage from '@/views/Home';
import UsersPage from '@/views/Users';
import ObjectsPage from '@/views/Properties';
import UtitsPage from '@/views/Units';
import UnitDetails from '@/views/Units/UnitDetails';
import ContractsPage from '@/views/Contracts';
import ClientsPage from '@/views/Clients';
import SettingsPage from '@/views/Settings';
import InvoicePage from '@/views/Invoices';
import ContractDetails from '@/views/Contracts/ContractDetails';
import AnnouncementPage from '@/views/Announcements';
import ComplainsPage from '@/views/Complains';
import TaskPage from '@/views/Tasks';
import ImportPage from '@/views/ImportExport';
import OrganizationsPage from '@/views/Organizations';

const ProtectedRoute = observer(
  ({ children, auth = false }: { children: JSX.Element; auth?: boolean }) => {
    const { isLoggedIn } = AuthStore;

    if (auth) {
      return !isLoggedIn ? children : <Navigate to={ROUTES.ROOT} />;
    }

    return isLoggedIn ? children : <Navigate to={ROUTES.AUTH} />;
  },
);

const ROUTER: CustomRouteConfig[] = [
  {
    path: ROUTES.AUTH,
    element: (
      <ProtectedRoute auth>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <LoginPage />,
        index: true,
      },
    ],
  },
  {
    path: ROUTES.ROOT,
    element: (
      <ProtectedRoute>
        <AdminLayout />
      </ProtectedRoute>
    ),
    children: [
      // Public links
      {
        path: '',
        element: <Navigate to={ROUTES.DASHBOARD} replace />,
        handle: { propertySpecific: true },
      },
      {
        path: ROUTES.DASHBOARD,
        element: <HomePage />,
        handle: { propertySpecific: true },
      },
      {
        path: ROUTES.UNITS,
        element: <UtitsPage />,
        handle: { propertySpecific: true },
      },
      {
        path: `${ROUTES.UNITS}/:id`,
        element: <UnitDetails />,
        handle: { propertySpecific: true },
      },
      {
        path: ROUTES.CONTRACTS,
        element: <ContractsPage />,
        handle: { propertySpecific: true },
      },
      {
        path: `${ROUTES.CONTRACTS}/:id`,
        element: <ContractDetails />,
        handle: { propertySpecific: true },
      },
      {
        path: ROUTES.CLIENTS,
        element: <ClientsPage />,
        handle: { propertySpecific: true },
      },
      {
        path: ROUTES.INVOICES,
        element: <InvoicePage />,
        handle: { propertySpecific: true },
      },
      {
        path: ROUTES.ANNOUNCEMENTS,
        element: <AnnouncementPage />,
        handle: { propertySpecific: true },
      },
      {
        path: ROUTES.TASKS,
        element: <TaskPage />,
        handle: { propertySpecific: true },
      },
      {
        path: ROUTES.COMPLAINS,
        element: <ComplainsPage />,
      },
      // Admin links
      {
        path: ROUTES.USERS,
        element: <UsersPage />,
      },
      {
        path: ROUTES.OBJECTS,
        element: <ObjectsPage />,
      },
      {
        path: ROUTES.SETTINGS,
        element: <SettingsPage />,
      },
      {
        path: ROUTES.IMPORT_EXPORT,
        element: <ImportPage />,
      },
      // Super admin links
      {
        path: ROUTES.ORGANIZATIONS,
        element: <OrganizationsPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.DASHBOARD} replace />,
    handle: { propertySpecific: true },
  },
];

const router = createBrowserRouter(ROUTER);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
