export const ENV = process.env.REACT_APP_NODE_ENV || 'development';
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const GOOGLE_CLIENT_ID =
  '647434470569-e802rqp0779bjtq14podicc6bq6o959l.apps.googleusercontent.com';

export const GET_HELP_EMAIL = 'admin@leezly.com';
export const DOCUMENTATION_URL =
  'https://app.sharefable.com/live/demo/leezly-streamline-your-fwqf1bq3vtlchjky';

export const GPT_URL =
  'https://chatgpt.com/g/g-673cbde9bc2c8191b8c07ab3a8725645-leezly-expert';

export const OTP_LENGTH = 4;

export const MIN_PAYMENT_DAY = 1;
export const MAX_PAYMENT_DAY = 28;

export const SELECTED_PROPERTY_HEADER = 'x-selected-property';

export enum CURRENCY {
  USD = 'USD',
  EUR = 'EUR',
  PLN = 'PLN',
  UAH = 'UAH',
}

export enum COUNTRIES {
  PL = 'PL',
  UA = 'UA',
}

export const SUPPORTED_LANGUAGES = {
  EN: 'en',
  RU: 'ru',
  UK: 'uk',
  PL: 'pl',
} as const;

export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.EN;

export enum CONTRACT_STATUS {
  DRAFT = 'DRAFT',
  PENDING_SIGNATURE = 'PENDING_SIGNATURE',
  ACTIVE = 'ACTIVE',
  OVERDUE = 'OVERDUE',
  CLOSED = 'CLOSED',
}

export enum INVOICE_STATUS {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PAID = 'PAID',
  OVERDUE = 'OVERDUE',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
}

export enum INVOICE_TYPE {
  REGULAR = 'REGULAR',
  CUSTOM = 'CUSTOM',
}

export enum INVOICE_ITEM_TYPE {
  DEPOSIT = 'DEPOSIT',
  REGULAR = 'REGULAR',
  OVERDUE_PENALTY = 'OVERDUE_PENALTY',
  CUSTOM = 'CUSTOM',
}

export const UPAID_INVOICE_STATUSES: InvoiceStatus[] = [
  INVOICE_STATUS.PENDING,
  INVOICE_STATUS.OVERDUE,
];

export enum PAYMENT_METHOD {
  ONLINE = 'ONLINE',
  BANK_TRANSFER = 'BANK_TRANSFER',
  TERMINAL = 'TERMINAL',
  CRYPTO = 'CRYPTO',
  CASH = 'CASH',
  MOBILE_TRANSFER = 'MOBILE_TRANSFER',
}

export enum DOCUMENT_TYPE {
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD = 'ID_CARD',
}

export enum PAYMENT_SHIFT_DAY {
  MIN = -15,
  MAX = 15,
  DEFAULT = 0,
}

export enum PAYMENT_SHIFT_WEEK_DAY {
  MIN = 0,
  MAX = 6,
  DEFAULT = 0,
}

export enum PAYMENT_CIRCLE {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export const INVOICE_EXPIRATION_DAYS = 7;

export enum COMPLAIN_STATUS {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export enum DATE_FILTERS {
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
}

export enum TASK_STATUS {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum TASK_TYPE {
  TASK = 'TASK',
  BUG = 'BUG',
}

export enum ADMIN_ROLE {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export const PAGINATION_SIZE_OPTIONS: PaginationLimits[] = [10, 20, 50, 100];
export const PAGINATION_DEFAULT_LIMIT = PAGINATION_SIZE_OPTIONS[0];

export const DEFAULT_PAGINATION_OPTIONS: PaginationRequestType = {
  page: 1,
  limit: PAGINATION_DEFAULT_LIMIT,
  sortBy: 'name',
  order: 'asc',
} as const;

export enum EXPENSE_STATUS {
  PLANNED = 'PLANNED',
  PAID = 'PAID',
}

export const WEEK_DAYS_NUMBER = Array.from({ length: 7 }, (_, i) => i);
