import { TFunction } from 'i18next';

import { INVOICE_STATUS, UPAID_INVOICE_STATUSES } from '@/constants/app';

export const mapInvoicesToColor = (status: InvoiceStatus) => {
  switch (status) {
    case 'PENDING':
      return 'yellow';
    case 'PAID':
      return 'green';
    case 'REFUNDED':
      return 'blue';
    case 'OVERDUE':
      return 'red';
    case 'CANCELLED':
      return 'orange';
    default:
      return 'gray';
  }
};

export const mapTypeToColor = (type: InvoiceType) => {
  switch (type) {
    case 'REGULAR':
      return 'green';
    case 'CUSTOM':
      return 'blue';
    default:
      return 'gray';
  }
};

export const mapPaymentToColor = (payment: PaymentMethod) => {
  switch (payment) {
    case 'ONLINE':
      return 'green';
    case 'BANK_TRANSFER':
      return 'blue';
    case 'CASH':
      return 'yellow';
    case 'CRYPTO':
      return 'purple';
    case 'MOBILE_TRANSFER':
      return 'orange';
    case 'TERMINAL':
      return 'pink';
    default:
      return 'gray';
  }
};

export const getInvoiceChartData = (
  statsData: InvoicesStats | undefined,
  t: TFunction,
) => {
  if (!statsData) return [];

  return Object.keys(statsData).map((key) => ({
    label: t(`invoices.statuses.${key}`),
    value: statsData[key as keyof InvoicesStats],
  }));
};

export const getInvoicesLegendChart = (
  statsData: InvoicesStats | undefined,
  t: TFunction,
) => {
  if (!statsData) return [];

  const data = Object.keys(statsData).reduce(
    (acc, _key) => {
      const key = _key as InvoiceStatus;
      const value = statsData[key];

      if (UPAID_INVOICE_STATUSES.includes(key)) {
        acc.UNPAID += value;
      } else if (key === INVOICE_STATUS.PAID) {
        acc.PAID += value;
      } else {
        acc.OTHER += value;
      }

      return acc;
    },
    {
      PAID: 0,
      UNPAID: 0,
      OTHER: 0,
    },
  );

  const total = Object.values(data).reduce((acc, val) => acc + val, 0);

  return [
    {
      label: t('contract_details.paid_invoices'),
      value: data.PAID,
      percent: ((data.PAID / total) * 100).toFixed(0),
    },
    {
      label: t('contract_details.unpaid_invoices'),
      value: data.UNPAID,
      percent: ((data.UNPAID / total) * 100).toFixed(0),
    },
    {
      label: t('contract_details.other_invoices'),
      value: data.OTHER,
      percent: ((data.OTHER / total) * 100).toFixed(0),
    },
  ];
};
