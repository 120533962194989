import { useState } from 'react';
import { Text, Button, Flex, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { MdCalendarToday } from 'react-icons/md';

import MiniCalendar from '@/components/Calendar';
import { changeToUtc, formateDateLocal } from '@/utils/date';

const DatesStep = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const [calendar, setCalendar] = useState<'start' | 'end' | null>(null);

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const handleDateChange = (key: 'start' | 'end') => (value: CalendarValue) => {
    const _value = changeToUtc(value as Date);

    setValue(key === 'start' ? 'startDate' : 'endDate', _value);

    setCalendar(null);
  };

  return (
    <Flex direction="row" justify="space-between" gap="50px">
      <Flex direction="column" gap="20px" mb="20px" flex={1}>
        <Text w="80px" whiteSpace="nowrap">
          {t('contract_form.start_date')}:
        </Text>

        <Button
          variant="outline"
          onClick={() => setCalendar('start')}
          rightIcon={<Icon as={MdCalendarToday} />}
        >
          {startDate
            ? formateDateLocal(new Date(startDate))
            : t('contract_form.select_start_date')}
        </Button>

        {errors.startDate && (
          <Text color="red.500" fontSize="sm">
            {typeof errors.startDate.message === 'string'
              ? errors.startDate.message
              : t('form.invalid')}
          </Text>
        )}
      </Flex>

      <Flex direction="column" gap="20px" flex={1}>
        <Text w="80px" whiteSpace="nowrap">
          {t('contract_form.end_date')}:
        </Text>

        <Button
          variant="outline"
          onClick={() => setCalendar('end')}
          rightIcon={<Icon as={MdCalendarToday} />}
        >
          {endDate
            ? formateDateLocal(new Date(endDate))
            : t('contract_form.select_end_date')}
        </Button>

        {errors.endDate && (
          <Text color="red.500" fontSize="sm">
            {typeof errors.endDate.message === 'string'
              ? errors.endDate.message
              : t('form.invalid')}
          </Text>
        )}
      </Flex>

      <MiniCalendar
        isOpen={!!calendar}
        onClose={() => setCalendar(null)}
        onChange={handleDateChange(calendar!)}
        value={calendar === 'start' ? startDate : endDate}
        maxDate={calendar === 'start' ? endDate : undefined}
        minDate={calendar === 'end' ? startDate : undefined}
      />
    </Flex>
  );
};

export default DatesStep;
