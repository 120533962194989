import { Avatar, Box, Flex, Text, useColorModeValue, FlexProps } from '@chakra-ui/react';

import defaultBg from '@/assets/img/banner_blue.jpg';

type Props = FlexProps & {
  title?: string;
  subtitle?: string;
  data?: { key: string; value: string }[];
  avatar?: string;
  bg?: string;
};

const ProfileCard = ({
  title,
  subtitle,
  data,
  avatar,
  bg = defaultBg,
  ...rest
}: Props) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue('white !important', '#111C44 !important');

  return (
    <Flex direction="column" align="center" {...rest}>
      <Box bg={`url(${bg})`} bgSize="cover" borderRadius="16px" h="100px" w="100%" />

      <Avatar
        src={avatar}
        name={title}
        bg="navy.200"
        h="90px"
        w="90px"
        border="4px solid"
        mt="-45px"
        borderColor={borderColor}
      />

      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {title}
      </Text>

      <Text color={textColorSecondary} fontSize="sm">
        {subtitle}
      </Text>

      <Flex
        mt="10px"
        justify="space-evenly"
        w="100%"
        direction={{
          base: 'column',
          md: 'row',
        }}
      >
        {data?.map((el) => (
          <Flex key={el.key} alignItems="center" direction="column">
            <Text color={textColorPrimary} fontSize="2xl" fontWeight="700" isTruncated>
              {el.key}
            </Text>

            <Text color={textColorSecondary} fontSize="sm" fontWeight="400" isTruncated>
              {el.value}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ProfileCard;
