import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Select,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { MdCalendarToday } from 'react-icons/md';

import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';
import { closeManually } from '@/api/invoice';
import { PAYMENT_METHOD } from '@/constants/app';
import MiniCalendar from '@/components/Calendar';
import { changeToUtc, formateDateLocal } from '@/utils/date';

type Props = {
  data: Invoice | null | undefined;
  setSelected: (data: Invoice | null | undefined) => void;
  onSubmit: () => void;
};

const CloseInvoiceManually = ({ data, setSelected, onSubmit }: Props) => {
  const [calendar, setCalendar] = useState(false);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<InvoiceCloseManuallyData>({
    defaultValues: {
      method: PAYMENT_METHOD.CASH,
      paidAt: new Date(),
      comments: '',
    },
  });

  const date = watch('paidAt');

  const handleOnClose = () => {
    reset();
    setSelected(undefined);
  };

  const handleSuccess = () => {
    onSubmit();
    handleOnClose();
  };

  const handleDateChange = (value: CalendarValue) => {
    const _value = changeToUtc(value as Date);
    setValue('paidAt', _value);
    setCalendar(false);
  };

  const { loading, trigger: submitTrigger } = useRequestState<'ok'>(
    () => closeManually(data!._id, { ...getValues() }),
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  return (
    <Popup
      size="xl"
      isOpen={data !== undefined}
      onClose={handleOnClose}
      closeOnOverlayClick={false}
      title={t('close_invoice_form.close_invoice_manually')}
      content={
        <form>
          <MiniCalendar
            isOpen={calendar}
            onClose={() => setCalendar(false)}
            onChange={handleDateChange}
            value={date}
            maxDate={new Date()}
          />

          <Stack spacing={4}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{
                base: 2,
                md: 5,
              }}
            >
              {/* Type */}
              <FormControl isRequired isInvalid={!!errors.method}>
                <FormLabel>{t('invoices.payment_method')}</FormLabel>

                <Select
                  disabled={loading}
                  {...register('method', { required: t('form.required') })}
                >
                  {Object.values(PAYMENT_METHOD).map((data) => (
                    <option key={data} value={data}>
                      {t(`invoices.payment_methods.${data}`)}
                    </option>
                  ))}
                </Select>

                <FormErrorMessage>{errors.method?.message}</FormErrorMessage>
              </FormControl>

              {/* Date */}
              <FormControl isRequired isInvalid={!!errors.paidAt}>
                <FormLabel>{t('invoices.paid_at')}</FormLabel>

                <Button
                  w="100%"
                  variant="outline"
                  onClick={() => setCalendar(true)}
                  rightIcon={<Icon as={MdCalendarToday} />}
                >
                  {date ? formateDateLocal(date) : '---'}
                </Button>
              </FormControl>
            </Flex>

            {/* Description */}
            <FormControl>
              <FormLabel>{t('invoices.comments')}</FormLabel>

              <Textarea maxLength={100} {...register('comments')} />

              <FormErrorMessage>{errors.comments?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </form>
      }
      footer={
        <HStack>
          <Button
            disabled={loading}
            type="submit"
            variant="brand"
            onClick={handleSubmit(submitTrigger)}
          >
            {t('form.save')}
          </Button>

          <Button disabled={loading} variant="outline" onClick={handleOnClose}>
            {t('form.cancel')}
          </Button>
        </HStack>
      }
    />
  );
};

export default observer(CloseInvoiceManually);
