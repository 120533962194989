import { DATE_FILTERS } from '@/constants/app';
import moment from 'moment';

export const createUtcDate = (date: string) => {
  return moment.utc(date).toDate();
};

export const changeToUtc = (date: Date) => {
  return moment(date).utcOffset(0, true).toDate();
};

export const formatDate = (
  date: Date | undefined | null,
  format = 'DD-MM-YYYY',
  placeholder = '-',
) => {
  if (!date) return placeholder;

  return moment(date).utc().format(format);
};

export const formateDateLocal = (
  date: Date | undefined | null,
  format = 'DD-MM-YYYY',
  placeholder = '-',
) => {
  if (!date) return placeholder;

  return moment(date).format(format);
};

export const calculateShiftDate = (date: Date, shiftDay: number) => {
  const utcDate = moment.utc(date);
  const monthStart = utcDate.clone().startOf('month');
  const monthEnd = utcDate.clone().endOf('month');
  const daysInMonth = monthEnd.date();

  let newDay = utcDate.date() + shiftDay;

  while (newDay < 1) {
    newDay += daysInMonth;
  }
  while (newDay > daysInMonth) {
    newDay -= daysInMonth;
  }

  return monthStart.clone().date(newDay).toDate();
};

export const getDatesByFilter = (filter: DATE_FILTERS) => {
  const now = moment.utc();

  switch (filter) {
    case DATE_FILTERS.THIS_MONTH:
      return {
        startDate: now.clone().startOf('month').toDate(),
        endDate: now.clone().endOf('month').toDate(),
      };
    case DATE_FILTERS.LAST_MONTH:
      return {
        startDate: now.clone().subtract(1, 'month').startOf('month').toDate(),
        endDate: now.clone().subtract(1, 'month').endOf('month').toDate(),
      };
    case DATE_FILTERS.THIS_YEAR:
      return {
        startDate: now.clone().startOf('year').toDate(),
        endDate: now.clone().endOf('year').toDate(),
      };
    case DATE_FILTERS.LAST_YEAR:
      return {
        startDate: now.clone().subtract(1, 'year').startOf('year').toDate(),
        endDate: now.clone().subtract(1, 'year').endOf('year').toDate(),
      };
    default:
      return {
        startDate: now.clone().startOf('month').toDate(),
        endDate: now.clone().endOf('month').toDate(),
      };
  }
};
