import { request } from '@/utils/request';

export const getContracts = async (query: PaginationRequestType) =>
  request<PaginateResult<Contract>>({
    url: '/contract',
    query,
  });

export const getContract = async (id: Contract['_id']) =>
  request<Contract>({
    url: '/contract',
    param: id,
  });

export const createContract = async (body: ContractFormData) =>
  request<Contract>({
    url: '/contract',
    method: 'POST',
    body,
  });

export const changeStatus = async ({
  id,
  status,
}: {
  id: Contract['_id'];
  status: ContractStatus;
}) =>
  request<Contract>({
    url: '/contract/status',
    method: 'POST',
    body: { status },
    param: id,
  });

export const removeContract = async (id: Contract['_id']) =>
  request<'ok'>({
    url: '/contract',
    method: 'DELETE',
    param: id,
  });

export const contractStats = async (id: Contract['_id']) =>
  request<InvoicesStats>({
    url: '/stats/contract',
    param: id,
  });

export const sendContract = async (id: Contract['_id']) =>
  request<'ok'>({
    url: '/contract/send',
    param: id,
  });
