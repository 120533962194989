import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton, Link, Text, Tooltip } from '@chakra-ui/react';
import { FaEnvelope, FaEye, FaFileContract, FaTrash } from 'react-icons/fa';
import { IoAdd, IoReload } from 'react-icons/io5';
import { BsFilter } from 'react-icons/bs';
import { AiFillSignature } from 'react-icons/ai';

import DataTable from '@/components/Table';
import { changeStatus, getContracts, removeContract, sendContract } from '@/api/contract';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import { formatDate } from '@/utils/date';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { ROUTES } from '@/constants/routes';
import { mapContractStatusToColor } from '@/utils/contracts';
import ContractForm from './components/form';
import ActionBar from '@/components/ActionBar';
import { CONTRACT_STATUS } from '@/constants/app';
import TableFilters from '@/components/TableFilters';
import useFilters from '@/hooks/useDataFilters';
import { isFiltered } from '@/utils/dataFilters';

type Filters = { status: ContractStatus[] };

const FILTERS: PaginationFilters<Filters> = {
  status: {
    title: 'contracts.status',
    values: Object.values(CONTRACT_STATUS).map((el) => ({
      value: el,
      selected: false,
      tKey: `contracts.statuses.${el}`,
    })),
  },
} as const;

const UnitsPage = () => {
  usePageInfo({ title: 'pages.contracts' });

  const { selectedProperty } = AppStore;

  const [toDelete, setTodelete] = useState<Contract | null>(null);
  const [toSend, setToSend] = useState<Contract | null>(null);
  const [selected, setSelected] = useState<Contract | null | undefined>(undefined);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDialogToggle = useCallback((data?: Contract | null) => {
    setTodelete(data ?? null);
  }, []);

  const handleSendDialogToggle = useCallback((data?: Contract | null) => {
    setToSend(data ?? null);
  }, []);

  const { isFiltersOpen, filters, setFiltersOpen, setFilter } = useFilters<Filters>({
    initialFilters: { status: [] },
  });

  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Contract>({
    initialSortBy: 'createdAt',
    initialOrder: 'desc',
  });

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Contract>>(
    () => getContracts({ page, limit, sortBy, order, search, ...filters }),
    [page, limit, sortBy, order, selectedProperty, search, filters],
  );

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    handleDialogToggle();
  }, [dataTrigger, handleDialogToggle]);

  const onSendSuccess = useCallback(() => {
    dataTrigger();
    handleSendDialogToggle();
  }, [dataTrigger, handleSendDialogToggle]);

  const { trigger: deleteTrigger, loading: deleteLoading } = useRequestState<'ok'>(
    () => removeContract(toDelete?._id!),
    [toDelete],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  const { trigger: sendTrigger, loading: sendLoading } = useRequestState<'ok' | Contract>(
    () => {
      if (toSend?.status === CONTRACT_STATUS.DRAFT) {
        return changeStatus({
          id: toSend._id!,
          status: CONTRACT_STATUS.PENDING_SIGNATURE,
        });
      }

      return sendContract(toSend?._id!);
    },
    [toSend],
    { condition: false, onSuccess: onSendSuccess },
  );

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          setSearch={setSearch}
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('contracts.total')}
              value={`${data?.totalDocs ?? '-'}`}
              icon={FaFileContract}
            />
          }
          actions={
            <>
              <ActionButton
                mini
                icon={BsFilter}
                onClick={() => setFiltersOpen(true)}
                redDot={isFiltered(filters)}
              />
              <ActionButton mini icon={IoAdd} onClick={() => setSelected(null)} />
              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<
          Contract,
          'client' | 'unit' | 'actions' | 'expirationDays' | 'monthlyPrice' | 'stripe'
        >
          mt="20px"
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'status',
              header: t('contracts.status'),
              accessor: 'status',
              cell: (data: Contract) => (
                <Badge colorScheme={mapContractStatusToColor(data.status)}>
                  {t(`contracts.statuses.${data.status}`)}
                </Badge>
              ),
            },
            {
              id: 'client',
              header: t('contracts.client'),
              accessor: 'client',
              cell: (data: Contract) => (
                <Text>
                  {data.data.client.name} {data.data.client.lastName}
                </Text>
              ),
            },
            {
              id: 'stripe',
              header: t('contracts.stripe'),
              accessor: 'stripe',
              cell: (data: Contract) => <Text>{data.data.property.stripe.name}</Text>,
            },
            {
              id: 'unit',
              header: t('contracts.unit'),
              accessor: 'unit',
              cell: (data: Contract) => (
                <Link onClick={() => navigate(`${ROUTES.UNITS}/${data.data.unit._id}`)}>
                  {data.data.unit.name}
                </Link>
              ),
            },
            {
              id: 'monthlyPrice',
              header: t('contracts.price'),
              accessor: 'monthlyPrice',
              cell: (data: Contract) => <Text>{data.data.unit.price}</Text>,
            },
            {
              id: 'skipInitialInvoice',
              header: t('contracts.initial_invoice'),
              accessor: 'skipInitialInvoice',
              isSortable: true,
              center: true,
              cell: (data: Contract) => (
                <Badge colorScheme={data.skipInitialInvoice ? 'red' : 'green'}>
                  {data.skipInitialInvoice ? t('common.no') : t('common.yes')}
                </Badge>
              ),
            },
            {
              id: 'signedAt',
              header: t('contracts.signed_date'),
              accessor: 'signedAt',
              isSortable: true,
              cell: (data: Contract) => <Text>{formatDate(data.signedAt)}</Text>,
            },
            {
              id: 'startDate',
              header: t('contracts.start_date'),
              accessor: 'startDate',
              isSortable: true,
              cell: (data: Contract) => <Text>{formatDate(data.startDate)}</Text>,
            },
            {
              id: 'endDate',
              header: t('contracts.end_date'),
              accessor: 'endDate',
              isSortable: true,
              cell: (data: Contract) => <Text>{formatDate(data.endDate)}</Text>,
            },
            {
              id: 'expirationDays',
              header: t('contracts.expiration_days'),
              accessor: 'expirationDays',
              isSortable: true,
              center: true,
              cell: (data: Contract) => (
                <Text>{data.data.property.invoiceExpirationDays}</Text>
              ),
            },
            {
              id: 'actions',
              header: t('contracts.actions'),
              accessor: null,
              center: true,
              cell: (data: Contract) => (
                <HStack justify="center">
                  {data.status === CONTRACT_STATUS.DRAFT && (
                    <Tooltip label={t('contracts.send_for_sign')}>
                      <IconButton
                        aria-label="Send"
                        size="sm"
                        icon={<AiFillSignature />}
                        onClick={() => handleSendDialogToggle(data)}
                      />
                    </Tooltip>
                  )}

                  {data.status !== CONTRACT_STATUS.DRAFT && (
                    <Tooltip label={t('contracts.send_contract')}>
                      <IconButton
                        aria-label="Send"
                        size="sm"
                        icon={<FaEnvelope />}
                        onClick={() => handleSendDialogToggle(data)}
                      />
                    </Tooltip>
                  )}

                  <Tooltip label={t('contracts.see_details')}>
                    <IconButton
                      aria-label="View"
                      size="sm"
                      icon={<FaEye />}
                      onClick={() => navigate(`${ROUTES.CONTRACTS}/${data._id}`)}
                    />
                  </Tooltip>

                  <Tooltip
                    label={t(
                      data.status === 'DRAFT'
                        ? 'contracts.delete_contract'
                        : 'contracts.you_cant_delete_only_draft',
                    )}
                  >
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<FaTrash />}
                      disabled={data.status !== 'DRAFT'}
                      onClick={() => handleDialogToggle(data)}
                    />
                  </Tooltip>
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <TableFilters<Filters>
        isOpen={isFiltersOpen}
        setFiltersOpen={setFiltersOpen}
        initialFilters={FILTERS}
        setFilter={setFilter}
      />

      <ConfirmDialog
        isOpen={!!toSend}
        loading={sendLoading}
        title={t('contracts.send_confirmation_title')}
        body={t('contracts.send_confirmation_body', {
          client: `${toSend?.data.client.name} ${toSend?.data.client.lastName}`,
        })}
        onConfirm={sendTrigger}
        onCancel={() => handleSendDialogToggle()}
      />

      <ConfirmDialog
        isOpen={!!toDelete}
        loading={deleteLoading}
        title={t('contracts.delete_confirmation_title')}
        body={t('contracts.delete_confirmation_body', {
          client: `${toDelete?.data.client.name} ${toDelete?.data.client.lastName}`,
        })}
        onConfirm={deleteTrigger}
        onCancel={() => handleDialogToggle()}
      />

      <ContractForm data={selected} setSelected={setSelected} onSubmit={dataTrigger} />
    </>
  );
};

export default observer(UnitsPage);
