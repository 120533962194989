import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { IoAdd, IoReload } from 'react-icons/io5';
import { TbReceipt2 } from 'react-icons/tb';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';

import AppStore from '@/stores/app';
import DataTable from '@/components/Table';
import Pagination from '@/components/Pagination';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import Search from '@/components/Search';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ActionBar from '@/components/ActionBar';
import { getExpenses, deleteExpense } from '@/api/expense';
import { EXPENSE_STATUS } from '@/constants/app';
import { formatDate } from '@/utils/date';
import ExpenseForm from './form';

type Props = {
  title?: string;
  unit?: Unit;
  byUnit?: boolean;
  layout?: {
    search?: boolean;
    total?: boolean;
    add?: boolean;
    reload?: boolean;
  };
  miniActions?: boolean;
  extraActions?: {
    beforeAll?: React.ReactNode;
    before?: React.ReactNode;
    after?: React.ReactNode;
    afterAll?: React.ReactNode;
  };
};

const ExpensesTable = ({
  title,
  unit,
  byUnit = false,
  miniActions = false,
  layout = {
    search: true,
    total: true,
    add: true,
    reload: true,
  },
}: Props) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [actionType, setActionType] = useState<'delete' | null>(null);
  const [actionData, setActionData] = useState<Expense | null>(null);
  const [selected, setSelected] = useState<Expense | null | undefined>(undefined);

  const handleConfirmDialog = useCallback((type: 'delete', data?: Expense | null) => {
    setActionType(type);
    setActionData(data ?? null);
    setIsConfirmDialogOpen(!!data);
  }, []);

  const { t } = useTranslation();
  const { selectedProperty } = AppStore;
  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Expense>({
    initialSortBy: 'createdAt',
    initialOrder: 'desc',
  });

  const conditionByUnit = byUnit ? !!unit : true;

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Expense>>(
    () => getExpenses({ page, limit, sortBy, order, search }, unit?._id),
    [page, limit, sortBy, order, selectedProperty, search, unit?._id],
    { condition: conditionByUnit },
  );

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    setIsConfirmDialogOpen(false);
  }, [dataTrigger]);

  const { trigger: deleteTrigger, loading: deleteLoading } = useRequestState<'ok'>(
    () => deleteExpense(actionData?._id!),
    [actionData],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  const handleConfirm = useCallback(async () => {
    if (actionType === 'delete') {
      deleteTrigger();
    }
  }, [actionType, deleteTrigger]);

  const getMiniActions = () => {
    if (!miniActions) return undefined;

    const result = [];

    if (layout.add) {
      result.push({ icon: IoAdd, onClick: () => setSelected(null) });
    }

    if (layout.reload) {
      result.push({ icon: MdRefresh, onClick: dataTrigger });
    }

    return result;
  };

  const loading = deleteLoading;

  return (
    <>
      <Flex direction="column" h="100%">
        {!miniActions && (
          <ActionBar
            setSearch={setSearch}
            stats={
              <ActionButton
                mini
                hoverable={false}
                name={t('expenses.total')}
                value={`${data?.totalDocs ?? '-'}`}
                icon={TbReceipt2}
              />
            }
            actions={
              <>
                <ActionButton mini icon={IoAdd} onClick={() => setSelected(null)} />
                <ActionButton mini icon={IoReload} onClick={dataTrigger} />
              </>
            }
          />
        )}

        <DataTable<Expense, 'actions'>
          title={title}
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          search={
            miniActions && layout.search ? (
              <Search
                mini
                reverseColor
                onChange={setSearch}
                placeholder="expenses.search_by_name_or_unit"
              />
            ) : undefined
          }
          actions={getMiniActions()}
          columns={[
            {
              id: 'name',
              header: t('expenses.name'),
              accessor: 'name',
              isSortable: true,
            },
            {
              id: 'price',
              header: t('expenses.price'),
              accessor: 'price',
              isSortable: true,
            },
            {
              id: 'description',
              header: t('expenses.description'),
              accessor: 'description',
              maxW: '150px',
            },
            {
              id: 'status',
              header: t('expenses.status'),
              accessor: 'status',
              isSortable: true,
              cell: (data: Expense) => (
                <Badge
                  colorScheme={data.status === EXPENSE_STATUS.PLANNED ? 'gray' : 'green'}
                >
                  {t(`expenses.statuses.${data.status}`)}
                </Badge>
              ),
            },
            {
              id: 'date',
              header: t('expenses.date'),
              accessor: 'date',
              isSortable: true,
              cell: (data: Expense) => (
                <Text>{data.date ? formatDate(data.date) : '-'}</Text>
              ),
            },
            {
              id: 'actions',
              header: t('expenses.actions'),
              accessor: null,
              center: true,
              cell: (data: Expense) => (
                <HStack justify="center">
                  <Tooltip
                    label={t('expenses.update_tooltip')}
                    aria-label="Update Expense"
                  >
                    <IconButton
                      aria-label="Update"
                      size="sm"
                      icon={<FaPencilAlt />}
                      onClick={() => setSelected(data)}
                    />
                  </Tooltip>

                  <Tooltip
                    label={t('expenses.delete_tooltip')}
                    aria-label="Delete Expense"
                  >
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<FaTrash />}
                      onClick={() => handleConfirmDialog('delete', data)}
                    />
                  </Tooltip>
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      {conditionByUnit && (
        <ExpenseForm
          unit={unit}
          data={selected}
          setSelected={setSelected}
          onSubmit={dataTrigger}
        />
      )}

      <ConfirmDialog
        loading={loading}
        isOpen={isConfirmDialogOpen}
        title={actionType === 'delete' ? t('expenses.delete_confirmation_title') : ''}
        body={
          actionType === 'delete'
            ? t('expenses.delete_confirmation_body', {
                name: actionData?.name,
              })
            : ''
        }
        onConfirm={handleConfirm}
        onCancel={() => setIsConfirmDialogOpen(false)}
      />
    </>
  );
};

export default observer(ExpensesTable);
