import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { MdCalendarToday } from 'react-icons/md';

import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';
import { createExpense, updateExpense } from '@/api/expense';
import { EXPENSE_STATUS } from '@/constants/app';
import MiniCalendar from '@/components/Calendar';
import { changeToUtc, formateDateLocal } from '@/utils/date';
import { DEFAULT_FORM_DATA } from './config';

type Props = {
  data: Expense | null | undefined;
  unit?: Unit;
  setSelected: (data: Expense | null | undefined) => void;
  onSubmit: () => void;
};

const ExpenseForm = ({ data, unit, setSelected, onSubmit }: Props) => {
  const [calendar, setCalendar] = useState(false);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ExpenseFormData | Expense>({
    defaultValues: data ?? DEFAULT_FORM_DATA,
    values: data ?? DEFAULT_FORM_DATA,
  });

  const date = watch('date');

  const handleOnClose = () => {
    reset();
    setSelected(undefined);
  };

  const handleSuccess = () => {
    onSubmit();
    handleOnClose();
  };

  const handleDateChange = (value: CalendarValue) => {
    const _value = changeToUtc(value as Date);

    setValue('date', _value);
    setCalendar(false);
  };

  const { loading, trigger: submitTrigger } = useRequestState<'ok'>(
    () => {
      const body = { ...getValues(), unit: unit?._id };

      if (data) {
        return updateExpense(data._id, body);
      }

      return createExpense(body);
    },
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  return (
    <Popup
      size="xl"
      isOpen={data !== undefined}
      onClose={handleOnClose}
      closeOnOverlayClick={false}
      title={data ? t('expenses_form.update_expense') : t('expenses_form.create_expense')}
      content={
        <form>
          <MiniCalendar
            isOpen={calendar}
            onClose={() => setCalendar(false)}
            onChange={handleDateChange}
            value={date}
          />

          <Stack spacing={4}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{
                base: 2,
                md: 5,
              }}
            >
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel>{t('expenses_form.name')}</FormLabel>
                <Input {...register('name', { required: t('form.required') })} />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              {/* Price */}
              <FormControl isRequired isInvalid={!!errors.price}>
                <FormLabel>{t('expenses_form.price')}</FormLabel>
                <Input
                  type="number"
                  {...register('price', { required: t('form.required') })}
                />
                <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{
                base: 2,
                md: 5,
              }}
            >
              {/* Type */}
              <FormControl isRequired isInvalid={!!errors.status}>
                <FormLabel>{t('expenses_form.status')}</FormLabel>

                <Select
                  disabled={loading}
                  {...register('status', { required: t('form.required') })}
                >
                  {Object.values(EXPENSE_STATUS).map((data) => (
                    <option key={data} value={data}>
                      {t(`expenses.statuses.${data}`)}
                    </option>
                  ))}
                </Select>

                <FormErrorMessage>{errors.status?.message}</FormErrorMessage>
              </FormControl>

              {/* Date */}
              <FormControl>
                <FormLabel>{t('expenses_form.date')}</FormLabel>

                <Button
                  w="100%"
                  variant="outline"
                  onClick={() => setCalendar(true)}
                  rightIcon={<Icon as={MdCalendarToday} />}
                >
                  {date ? formateDateLocal(date) : '---'}
                </Button>
              </FormControl>
            </Flex>

            {/* Description */}
            <FormControl>
              <FormLabel>{t('expenses_form.description')}</FormLabel>

              <Textarea maxLength={100} {...register('description')} />

              <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </form>
      }
      footer={
        <HStack>
          <Button
            disabled={loading}
            type="submit"
            variant="brand"
            onClick={handleSubmit(submitTrigger)}
          >
            {data?._id ? t('form.update') : t('form.save')}
          </Button>

          <Button disabled={loading} variant="outline" onClick={handleOnClose}>
            {t('form.cancel')}
          </Button>
        </HStack>
      }
    />
  );
};

export default observer(ExpenseForm);
