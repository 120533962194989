import { useState } from 'react';

interface FilterReturnType<F> {
  filters: PaginationFilterData<F>;
  isFiltersOpen: boolean;
  setFilter: (filters: PaginationFilterData<F>) => void;
  setFiltersOpen: (isOpen: boolean) => void;
  resetFilters: () => void;
}

interface FilterArgs<F> {
  initialFilters: PaginationFilterData<F>;
}

const useFilters = <F extends {}>({
  initialFilters,
}: FilterArgs<F>): FilterReturnType<F> => {
  const [filters, setFilters] = useState<typeof initialFilters>(initialFilters);
  const [isFiltersOpen, setFiltersOpen] = useState(false);

  const setFilter = (filters: typeof initialFilters) => {
    setFilters(filters);
  };

  const resetFilters = () => {
    setFilters({ ...initialFilters });
  };

  return {
    filters,
    isFiltersOpen,
    resetFilters,
    setFilter,
    setFiltersOpen,
  };
};

export default useFilters;
