import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { getStripeLink } from '@/api/stripe';
import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';

type Props = { isOpen: boolean; onClose: () => void };

const AddStripeAccount = ({ isOpen = false, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  let interval: NodeJS.Timeout | null = null;

  const { t } = useTranslation();

  const handleOnClose = () => {
    setLoading(false);
    onClose();
    reset();

    if (interval) {
      clearInterval(interval);
    }
  };

  const openNewTab = ({ url }: StripeUrlResponse) => {
    const newTab = window.open(url, '_blank');

    if (newTab) {
      interval = setInterval(() => {
        if (newTab.closed) {
          clearInterval(interval!);
          setLoading(false);
          handleOnClose();
        }
      }, 2000);
    }
  };

  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<StripeUrlRequest>({
    defaultValues: { name: '', country: '' },
  });

  const { trigger: getLinkTrigger } = useRequestState<StripeUrlResponse>(
    () => getStripeLink(getValues()),
    [],
    {
      condition: false,
      onSuccess: (data) => {
        openNewTab(data);
      },
      onError: () => {
        setLoading(false);
      },
    },
  );

  return (
    <Popup
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={() => handleOnClose()}
      title={t('settings.stripe.new_account_link')}
      content={
        loading ? (
          <Flex direction="column" align="center">
            <Text color="gray.400" mb="20px" fontSize="lg">
              {t('settings.stripe.please_finish')}
            </Text>

            <Spinner size="xl" mb="20px" />
          </Flex>
        ) : (
          <form>
            <Stack spacing={4} direction="row">
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel>{t('settings.stripe.name')}</FormLabel>

                <Input {...register('name', { required: t('form.required') })} />

                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        )
      }
      footer={
        !loading && (
          <HStack>
            <Button
              disabled={loading}
              type="submit"
              variant="brand"
              onClick={handleSubmit(() => {
                setLoading(true);
                getLinkTrigger();
              })}
            >
              {t('form.save')}
            </Button>

            <Button disabled={loading} variant="outline" onClick={() => handleOnClose()}>
              {t('form.cancel')}
            </Button>
          </HStack>
        )
      }
    />
  );
};

export default AddStripeAccount;
