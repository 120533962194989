import { request } from '@/utils/request';

export const importUnit = async (data: any[]) =>
  request<'ok'>({
    url: '/import/unit',
    body: data,
    method: 'POST',
  });

export const importClient = async (data: any[]) =>
  request<'ok'>({
    url: '/import/client',
    body: data,
    method: 'POST',
  });
